export const calculateDataToShowRanges = (currentPage, perPage, data) => {
    const start = currentPage == 1 ? 0 : (currentPage - 1) * perPage;
    const end = currentPage == 1 ? perPage : currentPage * perPage;

    // console.log('----start ', {start, end});
    const payload = data.slice(start, end);

    return payload;
};

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
};
export const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\d{10,15}$/;
    return regex.test(phoneNumber);
};

export const isValidPassword = (password) => {
    const hasNumber = /\d/.test(password);
    const hasUppercase = /[A-Z]/.test(password);

    return hasNumber && hasUppercase;
};

export const copyToClipboard = (value) => {
    var input = document.createElement("input");
    input.setAttribute("value", value);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    document.body.removeChild(input);
    return result;
};

export const isFacebookUrl = (value) => {
    const pattern = /^(https?:\/\/)?(www\.|web\.|mobile\.)?facebook\.com\/.+/;
    return pattern.test(value);
};

export const isTwitterUrl = (value) => {
    const pattern = /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/.+/;
    return pattern.test(value);
};

export function isValidInstagramPostUrl(url) {
    const instagramRegex = /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:p|reel)\/([a-zA-Z0-9_-]+)\/?/;
    return instagramRegex.test(url);
}

export function isValidInstagramProfileUrl(url) {
    const instagramProfileRegex = /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9_.]{1,20})\/?$/;
    const match = url.match(instagramProfileRegex);

    if (match) {
        return match[1]; // Return the username
    } else {
        return null; // Not a valid Instagram profile URL
    }
}

export function isValidTikTokVideoUrl(url) {
    const tikTokVideoRegex = /^(?:https?:\/\/)?(?:www\.)?tiktok\.com\/@[^\/]+\/video\/\d+\/?/;
    return tikTokVideoRegex.test(url);
}

export function isValidTikTokUsername(username) {
    // Regex to validate TikTok username with "@" at the start
    const regex = /^[a-zA-Z0-9._]{1,23}$/;
    return regex.test(username);
}

export const isValidHttpUrl = (string) => {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
};

export const getTweetId = (url) => {
    const matches = url.match(/status\/(\d+)/);
    if (matches && matches[1] && !isNaN(matches[1])) {
        return matches[1];
    }
    return null;
};

export const getYouTubeVideoId = (url) => {
    const match = url.match(
        /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?feature=player_embedded&v=))([^&\n?#]+)/,
    );

    if (match && match[1]) {
        return match[1];
    }

    return null;
};

export const getTikTokVideoId = (url) => {
    const match = url.match(/\/video\/(\d+)/);

    if (match && match[1]) {
        return match[1];
    }
    return null;
};

// export const isValidYouTubeUrl = (url) => {
//     const youtubeRegex = /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=[\w-]+(&\S*)?$/;
//     return youtubeRegex.test(url);
// }

export const isValidTikTokUrl = (url) => {
    const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/(@[\w-]+\/video\/\d+)(\?.*)?$/;
    return tiktokRegex.test(url);
};

export const isValidYouTubeUrl = (url) => {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[\w-]+(\?.*)?$/;
    return youtubeRegex.test(url);
};

export const isValidYouTubeChannel = (url) => {
    const youtubeChannelRegex = /^https?:\/\/(www\.)?youtube\.com\/.+/;
    return youtubeChannelRegex.test(url);
};

export const isValidDiscordInvite = (url) => {
    const discordInviteRegex = /^https?:\/\/(discord\.gg\/|discord(app)?\.com\/invite\/)[\w-]+$/;
    return discordInviteRegex.test(url);
};

export const isValidTelegramChannelLink = (url) => {
    const telegramChannelRegex = /^https?:\/\/(t\.me\/|telegram\.me\/)[\w-]+$/;
    return telegramChannelRegex.test(url);
};

export const isValidTelegramGroupInvite = (url) => {
    const telegramGroupInviteRegex = /^https?:\/\/t\.me\/joinchat\/[\w-]+$/;
    return telegramGroupInviteRegex.test(url);
};

export const isValidTwitchLink = (url) => {
    const twitchAccountLinkRegex = /^https?:\/\/(www\.)?twitch\.tv\/[\w-]+$/;
    return twitchAccountLinkRegex.test(url);
};

export function isValidDate(str) {
    const date = new Date(str);
    // console.log("--date", date);
    return !isNaN(date.getTime()) && typeof str === "string";
}

export const validateInputs = (model, fields) => {
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        if (!model[field.key]) return `${field.title} is required`;
    }
};

export const decodeHtmlEntities = (str) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value?.replace(/<[^>]*>/g, "");
};

export const roundDown = (number) => {
    return Math.floor(number * 100) / 100;
};
