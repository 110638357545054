<template>
    <div class="tooltip-new-container">
      <div
        class="tooltip-new-trigger"
        @mouseenter="showTooltip = true"
        @mouseleave="showTooltip = false"
      >
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="45" fill="#ccc" />
        <text x="50" y="65" text-anchor="middle" font-size="48" fill="#666" font-weight="bold">?</text>
    </svg>


      </div>
      <div v-if="showTooltip" class="tooltip-new">
        <div class="tooltip-content">
            Offer might be pending for several reasons:
            <ul style="padding-left: 20px;">
                <li>reason 1</li>
                <li>reason 2</li>
                <li>reason 3</li>
            </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showTooltip: false,
      };
    },
  };
  </script>
  
  <style>
  .tooltip-new-container {
    position: relative;
  }
  
  .tooltip-new-trigger {
    cursor: pointer;
    display: inline-block;
  }
  

  
  .tooltip-new-container:hover .tooltip {
    display: block;
  }
  .tooltip-new {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc; /* Nice border */
  padding: 10px;
  padding-bottom: 0px;
  border-radius: 5px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  /* display: none; */
  width: 250px; /* Adjust as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional drop shadow */
}

.tooltip-new-content ul {
  list-style-type: disc; /* Use bullets for list items */
  padding-left: 10px; /* Indentation for list items */
  font-size: 12px !important;

}
.tooltip-new-content{
  font-size: 12px;
}

.tooltip-container:hover .tooltip {
  display: block;
}
  </style>
  