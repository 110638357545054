import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import App from "./App";
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from "./router/index";
import "./assets/css/index.css";
import BlackDashboard from "./plugins/blackDashboard";
import store from "./store.js";
import i18n from "./i18n";
import "./registerServiceWorker";
// Import the functions you need from the SDKs you need
import { VueReCaptcha } from "vue-recaptcha-v3";
import firebase from "firebase/compat/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCC1bSB6fYIsUEFGl3bKSwVaaP0A5q77wc",
    authDomain: "contest-ccfaf.firebaseapp.com",
    projectId: "contest-ccfaf",
    storageBucket: "contest-ccfaf.appspot.com",
    messagingSenderId: "706121606053",
    appId: "1:706121606053:web:e20ffdbdab44d274213e4c",
};

firebase.initializeApp(firebaseConfig);

Vue.use(VueReCaptcha, { siteKey: "6LfyWG4pAAAAADtytYxwcBZUmFPAGiORpqEPNoZ-" });
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

/* eslint-disable no-new */
new Vue({
    store,
    router,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
