<template>
    <div>
        <table class="table tablesorter" :class="tableClass">
            <thead :class="theadClasses">
                <tr>
                    <slot name="columns">
                        <th 
                            v-for="column in columns" 
                            :key="column" @click="setSortBy(column)" :style="{cursor: isColumnSorted(column) && 'pointer'}">
                            <input
                                v-model="selectAll"
                                style="width: 15px"
                                v-if="column == 'select'"
                                class="form-control"
                                type="checkbox"
                            />
                            <div class="row align-items-center ml-0" v-else>
                                <span>{{ column }}</span>
                                <i
                                    v-if="sortBy == getSortByName(column)"
                                    class="tim-icons ml-2"
                                    :class="{
                                        'icon-minimal-down': sort == -1,
                                        'icon-minimal-up': sort == 1,
                                    }"
                                ></i>
                            </div>
                        </th>
                    </slot>
                </tr>
            </thead>
            <tbody :class="tbodyClasses">
                <tr v-if="!loading" v-for="(item, index) in showedData" :key="index">
                    <slot :row="item">
                        <td v-for="(column, index) in columns" :key="index" v-if="column == 'select'">
                            <input v-model="selected[item._id]" type="checkbox" />
                        </td>
                        <td
                            v-for="(column, index) in columns"
                            :key="index"
                            v-if="hasValue(item, column) && !['control', 'select', 'logo'].includes(column)"
                            style="max-width: 400px"
                            :style="{'min-width': column == 'Name' ? '100px' : 'unset' }"
                        >
                            <span v-if="column == 'Name'" style="word-break: break-word">
                                {{ displayName(item.name )}}
                            </span>
                            <span v-else-if="column == 'Countries'" style="word-break: break-word">{{
                                renderCountries(item.countries)
                            }}</span>
                            <span v-else-if="column == 'payout'" style="word-break: break-word">{{
                                item.payout
                            }}$</span>
                            <span v-else-if="column == 'Devices'" style="word-break: break-word">
                                <svg
                                    v-if="item.devices?.includes('android')"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#C6C5CA"
                                    class="bi bi-android2 mr-1"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="m10.213 1.471.691-1.26c.046-.083.03-.147-.048-.192-.085-.038-.15-.019-.195.058l-.7 1.27A4.832 4.832 0 0 0 8.005.941c-.688 0-1.34.135-1.956.404l-.7-1.27C5.303 0 5.239-.018 5.154.02c-.078.046-.094.11-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.697 3.697 0 0 0 3.5 5.02h9c0-.75-.208-1.44-.623-2.072a4.266 4.266 0 0 0-1.664-1.476ZM6.22 3.303a.367.367 0 0 1-.267.11.35.35 0 0 1-.263-.11.366.366 0 0 1-.107-.264.37.37 0 0 1 .107-.265.351.351 0 0 1 .263-.11c.103 0 .193.037.267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264Zm4.101 0a.351.351 0 0 1-.262.11.366.366 0 0 1-.268-.11.358.358 0 0 1-.112-.264c0-.103.037-.191.112-.265a.367.367 0 0 1 .268-.11c.104 0 .19.037.262.11a.367.367 0 0 1 .107.265c0 .102-.035.19-.107.264ZM3.5 11.77c0 .294.104.544.311.75.208.204.46.307.76.307h.758l.01 2.182c0 .276.097.51.292.703a.961.961 0 0 0 .7.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182c0 .276.097.51.292.703a.972.972 0 0 0 .71.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76c.291 0 .54-.103.749-.308.207-.205.311-.455.311-.75V5.365h-9v6.404Zm10.495-6.587a.983.983 0 0 0-.702.278.91.91 0 0 0-.293.685v4.063c0 .271.098.501.293.69a.97.97 0 0 0 .702.284c.28 0 .517-.095.712-.284a.924.924 0 0 0 .293-.69V6.146a.91.91 0 0 0-.293-.685.995.995 0 0 0-.712-.278Zm-12.702.283a.985.985 0 0 1 .712-.283c.273 0 .507.094.702.283a.913.913 0 0 1 .293.68v4.063a.932.932 0 0 1-.288.69.97.97 0 0 1-.707.284.986.986 0 0 1-.712-.284.924.924 0 0 1-.293-.69V6.146c0-.264.098-.491.293-.68Z"
                                    />
                                </svg>
                                <svg
                                    v-if="item.devices?.includes('ios')"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#C6C5CA"
                                    class="bi bi-apple"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"
                                    />
                                    <path
                                        d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"
                                    />
                                </svg>
                            </span>
                            <img v-else-if="column == 'Icon'" style="width: 50px; height: 50px" :src="item.logo" />
                            <span v-else-if="column === 'Status'">{{ item.active ? "Enabled" : "Disabled" }}</span>
                            <span v-else-if="column === 'Pinned'">{{ item.pinned ? "Yes" : "No" }}</span>
                            <span v-else>{{ itemValue(item, column) }}</span>
                        </td>

                        <td v-for="(column, index) in columns" :key="index" v-if="column == 'control'">
                            <base-button
                                class="mr-2"
                                style="width: 83px"
                                @click="viewOffer(item._id)"
                                size="sm"
                                type="info"
                                fill
                            >
                                <span style="margin-left: 0px !important"> View</span>
                            </base-button>
                            <base-button
                                style="width: 83px"
                                class="mr-2"
                                @click="switchStatus([item._id], item.active)"
                                size="sm"
                                :type="item.active ? 'danger' : 'secondary'"
                                fill
                            >
                                <clip-loader
                                    v-if="loadingItem?.['active'] == item._id"
                                    color="#fff"
                                    size="15px"
                                    :loading="true"
                                ></clip-loader>

                                <span style="margin-left: 0px !important" v-else>{{
                                    item.active ? "Disable" : "Enable"
                                }}</span>
                            </base-button>

                            <base-button
                                style="width: 83px"
                                class="mt-2"
                                @click="pinOffer([item._id], item.pinned)"
                                size="sm"
                                :type="item.pinned ? 'primary' : 'secondary'"
                                fill
                            >
                                <clip-loader
                                    v-if="loadingItem?.['pin'] == item._id"
                                    color="#fff"
                                    size="15px"
                                    :loading="true"
                                ></clip-loader>

                                <span style="margin-left: 0px !important" v-else>{{
                                    item.pinned ? "Unpin" : "Pin"
                                }}</span>
                            </base-button>
                        </td>
                    </slot>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { calculateDataToShowRanges, decodeHtmlEntities } from "../services/utils";
import BaseButton from "./BaseButton.vue";
import { Pagination } from "@/components";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    name: "offer-table",
    components: {
        BaseButton,
        Pagination,
        ClipLoader,
    },
    data() {
        return {
            selectAll: false,
            selected: {},
            sortBy: "_id",
            sort: 1,
        };
    },
    props: {
        columns: {
            type: Array,
            default: () => [],
            description: "Table columns",
        },
        data: {
            type: Array,
            default: () => [],
            description: "Table data",
        },
        type: {
            type: String, // striped | hover
            default: "",
            description: "Whether table is striped or hover type",
        },
        theadClasses: {
            type: String,
            default: "",
            description: "<thead> css classes",
        },
        tbodyClasses: {
            type: String,
            default: "",
            description: "<tbody> css classes",
        },
        switchStatus: {
            type: Function,
        },
        pinOffer: {
            type: Function,
        },
        viewOffer: {
            type: Function,
        },
        loadingItem: {
            type: Object,
        },
        perPage: { type: Number },
        currentPage: { type: Number },
        onSelect: {
            type: Function,
        },
        selectedItems: {
            type: Array,
        },
        loading: Boolean,
        sortedColumns: Array
    },
    updated() {},
    computed: {
        tableClass() {
            return this.type && `table-${this.type}`;
        },
        showedData() {
            const { sort, sortBy } = this;
            let data = this.data;
            if (sortBy) {
                // console.log('---data.length', data.length);
                data = data.sort((a, b) => {
                    // console.log("---inside", sortBy, sort);
                    const aValue = Array.isArray(a[sortBy]) ? a[sortBy].toString() : a[sortBy];
                    const bValue = Array.isArray(b[sortBy]) ? b[sortBy].toString() : b[sortBy];
                    // console.log(isNaN(a?.[sortBy]), a[sortBy].localeCompare);
                    if (sort == 1) {
                        return isNaN(aValue) && aValue?.localeCompare && bValue?.localeCompare
                            ? aValue?.localeCompare(bValue)
                            : aValue - b?.[sortBy];
                    } else {
                        return isNaN(aValue) && aValue?.localeCompare && bValue?.localeCompare
                            ? bValue?.localeCompare(aValue)
                            : b?.[sortBy] - aValue;
                    }
                });
            }

            data = calculateDataToShowRanges(this.currentPage, this.perPage, data);

            // for (let i = 0; i < data.length; i++) {
            //   const offer = data[i];
            //   console.log('---offer', this.pinnedOffers, offer._id);
            //   if (this.pinnedOffers?.includes(offer._id)) {
            //     offer.pinned = true
            //   }
            // }

            return data;
        },
        totalPages() {
            return this.data.length / this.perPage;
        },
    },
    mounted() {
        console.log("---mounted", this.columns);
    },
    methods: {
        hasValue(item, column) {
            return item[column.toLowerCase()] !== "undefined";
        },
        itemValue(item, column) {
            return item[column.toLowerCase()];
        },
        setSortBy(column) {
            if (!this.isColumnSorted(column)) return;
            switch (column) {
                case "Status":
                    this.sortBy = "active";
                    break;
                default:
                    this.sortBy = column?.toLowerCase();
                    break;
            }

            this.sort = !this.sort || this.sort == -1 ? 1 : -1;
        },
        getSortByName(column) {
            switch (column) {
                case "Status":
                    return "active";
                default:
                    return column.toLowerCase();
            }
        },
        isColumnSorted(column) {
            return this.sortedColumns.includes(column)
        },
        renderCountries(countries) {
            if (countries.length > 15) {
                const shortCountries = [...countries].slice(0, 15);
                return `${shortCountries.toString()}...`
            }

            return countries.toString()
        },
        displayName(name) {
            return decodeHtmlEntities(name)
        }
    },
    watch: {
        currentPage() {},
        selectAll(value) {
            this.onSelect(
                this.showedData.map((x) => x._id),
                value,
            );
        },
        selectedItems(value) {
            const obj = {};
            for (let i = 0; i < value.length; i++) {
                obj[value[i]] = true;
            }
            if (JSON.stringify(obj) !== JSON.stringify(this.selected)) {
                this.selected = { ...obj };
            }
        },
        selected(value) {
            const ids = Object.keys(value);
            this.onSelect(ids, value);
        },
    },
};
</script>
<style></style>
