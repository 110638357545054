<template>
  <nav class="navbar navbar-expand-lg navbar-absolute"
       :class="{'bg-white': showMenu, 'navbar-transparent': !showMenu}">
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div class="navbar-toggle d-inline" :class="{toggled: $sidebar.showSidebar}">
          <button type="button"
                  class="navbar-toggler"
                  aria-label="Navbar toggle button"
                  @click="toggleSidebar">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <a class="navbar-brand" href="#pablo">{{routeName}}</a>
      </div>
      <button class="navbar-toggler" type="button"
              @click="toggleMenu"

              >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <!-- <collapse-transition> -->
        <div class="mt-3 pl-4 show-mobile" style="width: 100%;" v-if="showMenu">
          <div class="">
              <div class="nav-link row mx-0 justify-content-center p-1  cursor-pointer" @click="to('/profile')"
                
              >
                <div class="photo">
                  <img src="/img/anime3.png">
                </div>
                <a  href="#" class="nav-item dropdown-item pl-3" style="display: inline;">Profile</a>
              </div>
              <div class="dropdown-divider"></div>

              <li class="nav-link row mx-0 justify-content-center align-items-center">
                <div class="photo p-1 mr-1" style="border-radius: 0px;">
                  <i class="fas fa-sign-out-alt" style="font-size: 25px; color: #525F7F;"></i>

                </div>
                <a @click="logout" href="#" class="nav-item dropdown-item pl-3" style="display: inline;">Log out</a>
              </li>
            </div>
        </div>

        <div class="collapse navbar-collapse show hide-mobile" v-show="showMenu">
          <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
            <base-dropdown tag="li"
                           :menu-on-right="!$rtl.isRTL"
                           title-tag="a"
                           class="nav-item"
                           menu-classes="dropdown-navbar">
              <a slot="title" href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
                <div class="photo">
                  <img src="/img/anime3.png">
                </div>
                <b class="caret d-none d-lg-block d-xl-block"></b>
                <p @click="logout" class="d-lg-none">
                  Log out
                </p>
              </a>
              <li class="nav-link">
                <a @click="to('/profile')" href="#" class="nav-item dropdown-item">Profile</a>
              </li>
              <!-- <li class="nav-link">
                <a @click="to('/offerwall')" href="#" class="nav-item dropdown-item">Settings</a>
              </li> -->
              <div class="dropdown-divider"></div>
              <li class="nav-link">
                <a @click="logout" href="#" class="nav-item dropdown-item">Log out</a>
              </li>
            </base-dropdown>
          </ul>
        </div>
      <!-- </collapse-transition> -->
    </div>
  </nav>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';
  import Modal from '@/components/Modal';
  import Auth from '../../services/auth';

  export default {
    components: {
      CollapseTransition,
      Modal
    },
    computed: {
      routeName() {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
      },
      isRTL() {
        return this.$rtl.isRTL;
      }
    },
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: ''
      };
    },
    methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      logout() {
        console.log('---logout');
        Auth.logout();
        window.location.href = "/login"
      },
      to(path) {
        this.$router.replace(path)
      },
      changeTheme(){
        const current = localStorage.getItem("theme");
        if (current == "black"){
          localStorage.setItem("theme", "white");
        } else {
          localStorage.setItem("theme", "black");
        }
        window.location.reload()
        
      }
    }
  };
</script>
<style scoped>
.dropdown-item.active,
.dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: unset;
    background-color: unset;
    
}
</style>
