<template>
    <div class="row" style="background-color: white">
        <modal :show="show" id="searchModal" :centered="false" :show-close="true" :enable-theme="false">
            <span class="mb-1">Please enter your username</span>
            <base-input class="text-lg" placeholder="Username" v-model="username"></base-input>
            <div class="row justify-content-center">
                <base-button :disabled="!username" class="mt-2" :style="{background: buttonColor}" @click="submit"
                    >Complete Offer</base-button
                >
                <base-button class="mt-2 px-4" type="secondary" size="sm" @click="close">Close</base-button>
            </div>
        </modal>
    </div>
</template>
<script>
import { calculateDataToShowRanges } from "../../services/utils";
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Modal from "@/components/Modal";
import "./historyTable.css";

export default {
    name: "offer-table",
    components: {
        BaseButton,
        BaseInput,
        ClipLoader,
        Modal,
    },
    data() {
        return {
            username: null,
        };
    },
    props: {
        show: Boolean,
        openAction: Function,
        item: Object,
        close: Function,
        buttonColor: String
    },

    methods: {
        submit() {
            this.openAction(this.item, this.username);
            this.close();
        },
    },
    watch: {},
};
</script>
<style></style>
