<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="row" style="background-color: white">
        <modal :show="show" id="searchModal" :centered="false" :show-close="true" :enable-theme="false">
            <div class="row px-md-2 contest-login-model" :style="{ 'background-color': 'white' }">
                <h4 style="font-weight: bold; color: #333; padding-left: 15px; font-family: Open Sans;">In order to contact you, we need an email</h4>
                <div class="col-md-12">
                    <base-input
                        :style="{ color: '#333 !important', 'font-family': 'Open Sans', 'font-weight': 500 }"
                        :color="'#333'"
                        label="Full name"
                        placeholder="Full name"
                        v-model="model.name"
                    >
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input
                        style="color: #333 !important; 'font-weight': 500"
                        label="Email"
                        placeholder="email@gmail.com"
                        v-model="model.email"
                    >
                    </base-input>
                </div>

                <div class="col-md-12">
                    <h5 class="text-danger mt-4" style="font-size: 1em" v-if="error || formError">
                        {{ formError || error }}
                    </h5>
                </div>
                <div class="px-3 mt-4" style="display: flex; flex-direction: row; justify-content: center">
                    <base-button class="mr-3" @click="confirm" slot="footer" :style="{background: buttonColor}" fill>
                        <span style="color: white;">Save</span>
                    </base-button>
                    <base-button class="mr-3" @click="close" slot="footer" fill style="background: #333">
                        <span style="color: white;">Cancel</span>
                    </base-button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import Modal from "@/components/Modal";

import { BaseAlert } from "@/components";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { validateEmail } from "../../services/utils";

export default {
    components: {
        BaseAlert,
        Modal,
        ClipLoader,
    },
    data() {
        return {
            theme: "white",
            formError: null,
        };
    },
    props: {
        buttonColor: String,
        model: {
            type: Object,
            default: () => {
                return {};
            },
        },
        show: {
            type: Boolean,
            default: false,
        },
        save: {
            type: Function,
        },
        close: {
            type: Function,
        },
        loading: Boolean,
        error: String,
    },
    methods: {
        confirm() {
            const { name, email } = this.model;
            if (!name) {
                this.formError = "Name is required";
                return;
            }

            if (!validateEmail(email)) {
                this.formError = "Email is invalid";
                return;
            }

            this.formError = null;
            this.save();
        },
    },
};
</script>
<style></style>
