<template>
    <div class="row" style="background-color: white">

        <modal :width="400" :show="show" id="searchModal" :centered="false" :show-close="true" :enable-theme="false">
            <div class="row justify-content-center align-items-center">
                <h4 class="mb-1" style="font-weight: 700; font-size: 1.2rem; text-align: center;">🌟 Unlock Exclusive Rewards! 🌟</h4>
                <!-- <base-button
                  @click="openDiscord()"
                  :backgroundColor="'#5865F2'"
                  :type="'primary'"
                  class="mb-2"
                  size="sm"
                  
              >
                  <span style="margin-left: 0px !important; color: white;" ><i style="font-size: 16px" class="fab fa-discord mr-2"></i>Help</span>
              </base-button> -->
            </div>
            <div class="my-4 text-center">
                <p> Complete your first offer and dive into exclusive social shares to earn massive entries. Seize this chance to amplify your wins and claim amazing prizes. Start your winning journey now!</p>
                
            </div>
            <div class="row justify-content-center">
                <base-button style="background: #333333;" size="" @click="close">Complete First Offer</base-button>
            </div>
        </modal>
    </div>
</template>
<script>
import { calculateDataToShowRanges } from "../../services/utils";
import BaseButton from "@/components/BaseButton.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Modal from "@/components/Modal";
import "./historyTable.css";
// import Vue from 'vue'
import HelpTooltip from './HelpTooltip.vue'

// Vue.directive('tooltip', VTooltip)
// Vue.directive('close-popover', VClosePopover)
// Vue.component('v-popover', VPopover)
// Vue.use(Tooltip);

export default {
    name: "complete-offer-notice",
    components: {
        BaseButton,
        ClipLoader,
        Modal,
        // VPopover,
        HelpTooltip
    },
    data() {
        return {

        };
    },
    props: {
        show: Boolean,
        close: Function,
    },
    updated() {},
    mounted() {},
    computed: {

    },
    methods: {

    },
    watch: {


    },
};
</script>
<style></style>
