<template>
    <SlideYUpTransition :duration="animationDuration">
        <div
            class="modal fade"
            @click.self="closeModal"
            :class="[{ 'show d-block': show }, { 'd-none': !show }, { 'modal-mini': type === 'mini' }]"
            v-show="show"
            tabindex="-1"
            role="dialog"
            :aria-hidden="!show"
        >
            <div
                class="modal-dialog"
                :style="{'max-width': `${width}px`}"
                :class="[{ 'modal-notice': type === 'notice' }, { 'modal-dialog-centered': centered }, modalClasses]"
                style="max-height: calc(100vh - 120vh * 0.30); overflow-y: auto;"
            >
                <div
                    class="modal-content add-offerwall-bg"
                    :style="{ 'background-color': enableTheme && theme == 'black' ? '#28293D' : 'white'}"
                    :class="[gradient ? `bg-gradient-${gradient}` : '', modalContentClasses]"
                >
                    <div class="modal-header" :class="[headerClasses]" v-if="$slots.header">
                        <slot name="header"></slot>
                        <slot name="close-button">
                            <button
                                type="button"
                                class="close"
                                v-if="showClose"
                                @click="closeModal"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <i class="tim-icons icon-simple-remove"></i>
                            </button>
                        </slot>
                    </div>

                    <div v-if="$slots.default" class="modal-body" :class="bodyClasses">
                        <slot></slot>
                    </div>

                    <div class="modal-footer" :class="footerClasses" v-if="$slots.footer">
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </SlideYUpTransition>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions";

export default {
    name: "modal",
    components: {
        SlideYUpTransition,
    },
    data() {
        return {
            theme: localStorage.getItem("theme"),
        };
    },
    props: {
        show: Boolean,
        showClose: {
            type: Boolean,
            default: true,
        },
        centered: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            default: "",
            validator(value) {
                let acceptedValues = ["", "notice", "mini"];
                return acceptedValues.indexOf(value) !== -1;
            },
            description: 'Modal type (notice|mini|"") ',
        },
        modalClasses: {
            type: [Object, String],
            description: "Modal dialog css classes",
        },
        modalContentClasses: {
            type: [Object, String],
            description: "Modal dialog content css classes",
        },
        gradient: {
            type: String,
            description: "Modal gradient type (danger, primary etc)",
        },
        headerClasses: {
            type: [Object, String],
            description: "Modal Header css classes",
        },
        bodyClasses: {
            type: [Object, String],
            description: "Modal Body css classes",
        },
        footerClasses: {
            type: [Object, String],
            description: "Modal Footer css classes",
        },
        animationDuration: {
            type: Number,
            default: 500,
            description: "Modal transition duration",
        },
        enableTheme: {
            type: Boolean,
            default: true,
        },
        width: {
            type: Number
        },
        overflowY: String
    },
    methods: {
        closeModal() {
            this.$emit("update:show", false);
            this.$emit("close");
        },
    },
    watch: {
        show(val) {
            let documentClasses = document.body.classList;
            if (val) {
                documentClasses.add("modal-open");
            } else {
                documentClasses.remove("modal-open");
            }
        },
    },
};
</script>
<style>
.modal.show {
    background-color: rgba(0, 0, 0, 0.3);
}
.modal-content {
    max-height: calc(100vh - 120vh * 0.30); overflow-y: auto;
}
</style>
