<template>
    <div v-if="item.key.includes('telegram-')">
        <div>
            <a
                :href="`${item.inputs[0]?.value}`"
                target="_blank"
                class="telegram-button"
            >
                <i class="fab fa-telegram telegram-icon" style=""></i>
                {{ content[item.key]?.["button-title"] }}
            </a>
        </div>

        <div class="mt-4" style="cursor: pointer" @click="onConfirm(item)">
            <span style="text-decoration: underline">{{ content[item.key]?.text }}</span>
        </div>
    </div>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Modal from "@/components/Modal";

const content = {
    'telegram-group': {
        'button-title': 'Join',
        'text': 'Already Joined?'
    },
    'telegram-channel': {
        'button-title': 'Join',
        'text': 'Already Joined?'
    },
}

export default {
    name: "telegram-social-card",
    components: {
        BaseButton,
        ClipLoader,
        Modal,
        // VPopover,
    },
    data() {
        return {
            selectAll: false,
            selected: {},
            content: content
        };
    },
    props: {
        item: Object,
        onConfirm: Function
    },
    updated() {},
    mounted() {},

};
</script>
<style scoped>
.telegram-button {
    background-color: rgb(0, 136, 204);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

</style>
