<template>
    <card>
        <h4 slot="header" class="title" style="color: #00DA48">Preview</h4>
        <div class="p-2" style="background: linear-gradient(290deg, #55df98, #455b97)" v-if="contest">
            <contest-card v-if="initialized" :contest="contest" :demoOffers="demoOffers" :isDemo="true"></contest-card>
        </div>
    </card>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import ContestCard from "../../pages/Contest/ContestCard";

export default {
    name: "base-alert",
    components: {
        FadeTransition,
        ContestCard,
    },
    props: {
        link: {
            type: String,
        },
        device: String,
        contest: Object,
        demoOffers: Array,
        initialized: Boolean,
    },
    data() {
        return {
            visible: true,
        };
    },
    computed: {},
    methods: {
        dismissAlert() {
            this.visible = false;
        },
    },
};
</script>
