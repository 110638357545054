<template>
    <div>
        <table class="table tablesorter" :class="tableClass">
            <thead :class="theadClasses">
                <tr>
                    <slot name="columns">
                        <th v-for="column in columns" :key="column.key" @click="setSortBy(column.key)" style="cursor: pointer">
                            <input
                                v-model="selectAll"
                                style="width: 15px"
                                v-if="column.key == 'select'"
                                class="form-control"
                                type="checkbox"
                            />
                            <div v-else>
                                <span>{{ column.title }}</span>
                                <i
                                    v-if="sortBy?.toLowerCase() == column?.key?.toLowerCase()"
                                    class="tim-icons ml-2"
                                    :class="{
                                        'icon-minimal-down': sort == -1,
                                        'icon-minimal-up': sort == 1,
                                    }"
                                ></i>
                            </div>
                        </th>
                    </slot>
                </tr>
            </thead>
            <tbody :class="tbodyClasses">
                <tr v-if="!loading" v-for="(item, index) in showedData" :key="index">
                    <slot :row="item">
                        <td v-for="(column, index) in columns" :key="index" v-if="column.key == 'select'">
                            <input v-model="selected[item.id]" type="checkbox" />
                        </td>
                        <td
                            v-for="(column, index) in columns"
                            :key="index"
                            v-if="hasValue(item, column.key) && !['control', 'select', 'icon'].includes(column.key)"
                        >
                            <!-- <span v-if="column.key === 'Status'">{{ getStatus(item) }}</span>
                            <span v-if="column.key === 'Winner'">{{ (getStatus(item)=== 'Ended' && item.winner?.user_id ) || '-' }}</span>
                            <span v-if="column.key === 'Social shares'">{{
                                item.is_social_active ? "Enabled" : "disabled"
                            }}</span>
                            <span v-if="column.key === 'Payout threshold'">{{ item.payout_threshold }}</span> -->
                            <span v-if="column.key === 'winners'">
                                <p v-for="winner in item.winners"  >
                                    <a :href="`/users/${winner.user_id}`">- {{ winner.user_id }}</a>
                                </p>
                                <p v-if="item.winners?.length <= 0">
                                    -
                                </p>
                            </span>
                            <span v-else-if="column.key === 'date.start'">{{ showDate(item.date?.start) }}</span>
                            <span v-else-if="column.key === 'date.end'">{{ showDate(item.date?.end) }}</span>
                            <span v-else>
                                {{ itemValue(item, column.key, column.method) }}
                            </span>
                        </td>
                        <td v-for="(column, index) in columns" :key="index" v-if="column.key == 'control'">
                            <base-button
                                class="mr-2 mb-1"
                                @click="copyLink(item._id, item.link_alias)"
                                size="sm"
                                :type="'info'"
                                fill
                            >
                                <span style="margin-left: 0px !important">Copy public link</span>
                            </base-button>

                            <base-button
                                style="width: 83px"
                                @click="edit(item._id)"
                                class="mb-1 mr-2"
                                size="sm"
                                type="secondary"
                                fill
                                >Edit</base-button
                            >

                            <base-button
                                style="width: 83px"
                                class="mb-1"
                                @click="remove(item)"
                                size="sm"
                                type="danger"
                                fill
                            >
                                <clip-loader
                                    v-if="loadingItem?.['delete'] == item._id"
                                    color="#fff"
                                    size="15px"
                                    :loading="true"
                                ></clip-loader>

                                <span style="margin-left: 0px !important" v-else>Delete</span>
                            </base-button>
                        </td>
                    </slot>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import BaseButton from "./BaseButton.vue";
import { Pagination } from "@/components";
import moment from "moment";
import { calculateDataToShowRanges, isValidDate } from "../services/utils";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    name: "offer-table",
    components: {
        BaseButton,
        Pagination,
        ClipLoader,
    },
    data() {
        return {
            selectAll: false,
            selected: {},
            sortBy: "_id",
            sort: -1,
        };
    },
    props: {
        columns: {
            type: Array,
            default: () => [],
            description: "Table columns",
        },
        data: {
            type: Array,
            default: () => [],
            description: "Table data",
        },
        type: {
            type: String, // striped | hover
            default: "",
            description: "Whether table is striped or hover type",
        },
        theadClasses: {
            type: String,
            default: "",
            description: "<thead> css classes",
        },
        tbodyClasses: {
            type: String,
            default: "",
            description: "<tbody> css classes",
        },
        remove: {
            type: Function,
        },
        edit: {
            type: Function,
        },
        perPage: { type: Number },
        currentPage: { type: Number },
        onSelect: {
            type: Function,
        },
        selectedItems: {
            type: Array,
        },
        loadingItem: Object,
        loading: Boolean,
        sortedColumns: Array

    },
    updated() {},
    computed: {
        tableClass() {
            return this.type && `table-${this.type}`;
        },
        showedData() {
            let data = this.data;
            const { sort, sortBy } = this;

            // console.log('---sort', sort, sortBy);
            if (sortBy) {
                data = data.sort((a, b) => {
                    // console.log("---inside", sortBy, sort);
                    // console.log(isNaN(a?.[sortBy]), a[sortBy].localeCompare);
                    let aValue = this.itemValue(a, sortBy)
                    let bValue = this.itemValue(b, sortBy);
                    if (Array.isArray(aValue) || Array.isArray(bValue)) {
                        aValue = aValue?.length || 0
                        bValue = bValue?.length || 0
                    } else {
                        aValue = aValue.toString()
                        bValue = bValue.toString()
                    }
                    
                    // console.log('---isValidDate', isValidDate(bValue), );

                    // if (isValidDate(aValue)) aValue = new Date(aValue).getTime();
                    // if (isValidDate(bValue)) bValue = new Date(bValue).getTime();

                    // console.log('----aValue, bValue', aValue, bValue);

                    if (sort == 1) {
                        return isNaN(aValue) && aValue?.localeCompare && bValue?.localeCompare
                            ? aValue?.localeCompare(bValue)
                            : aValue - bValue;
                    } else {
                        return isNaN(aValue) && aValue?.localeCompare && bValue?.localeCompare
                            ? bValue?.localeCompare(aValue)
                            : bValue - aValue;
                    }
                });
            }

            data = calculateDataToShowRanges(this.currentPage, this.perPage, data);
            return data;
        },
        totalPages() {
            return this.data.length / this.perPage;
        },
    },
    methods: {
        hasValue(item, column) {
            return item[column?.toLowerCase()] !== "undefined";
        },
        itemValue(item, column) {
            // console.log('---itemValue', item, column);
            const method = this.columns.find(x => x.key === column)?.method
            if (method && this[method]) {
                const value = this[method](item)
                // console.log('---value', value);
                return value;
            }
            // console.log('---column', column);
            const parts = column.split('.');
            if (parts.length > 1) {
                let obj = item;
                for (let i = 0; i < parts.length; i++) {
                    const part = parts[i];
                    obj = obj[part] || '-'
                }

                // if (column == "date.start") return this.showDate(obj)
                // if (column == "date.end") return this.showDate(obj)
                // console.log('---obj', obj);
                return obj 
            }


            return item[column.toLowerCase()] || '-';
        },
        setSortBy(column) {
            if (column === "control") return;
            this.sortBy = column?.toLowerCase();
            
            this.sort = !this.sort || this.sort == -1 ? 1 : -1;
        },
        showDate(date) {
            return moment(date).format("DD MMM YYYY hh:mm");
        },
        copyLink(id, link_alias) {
            var input = document.createElement("input");
            if (link_alias) {
                input.setAttribute("value", `https://adviral.link/${link_alias}`);
            } else {
                input.setAttribute("value", `https://adviral.link/contest/${id}/public`);
            }
            document.body.appendChild(input);
            input.select();
            var result = document.execCommand("copy");
            document.body.removeChild(input);
            this.$notify({
                message: "Link Successfully Copied",
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
                timeout: 3000,
            });
            return result;
        },
        getStatus(item) {
            if (item.active) {
                const startDate = new Date(item.date?.start).getTime()
                const endDate = new Date(item.date?.end).getTime()
                const currentDate = new Date().getTime()

                if (startDate > currentDate) {
                    return "Scheduled"
                } else if (startDate < currentDate && endDate > currentDate) {
                    return "In progress"
                } else if (endDate < currentDate) {
                    return "Ended"
                }

            }
            return "disabled"
        }
    },
    watch: {
        currentPage() {},
        selectAll(value) {
            this.onSelect(
                this.showedData.map((x) => x.id),
                value,
            );
        },
        selectedItems(value) {
            const obj = {};
            for (let i = 0; i < value.length; i++) {
                obj[value[i]] = true;
            }
            if (JSON.stringify(obj) !== JSON.stringify(this.selected)) {
                this.selected = { ...obj };
            }
        },
        selected(value) {
            const ids = Object.keys(value);
            this.onSelect(ids, value);
        },
    },
};
</script>
<style></style>
