export default {
    colors: {
        default: "#344675",
        primary: "#00DA48",
        info: "#1d8cf8",
        danger: "#fd5d93",
        teal: "#00d6b4",
        primaryGradient: ["rgba(76, 211, 150, 0.1)", "rgba(53, 183, 125, 0)", "rgba(119,52,169,0)"],
    },
    API_URL: process.env.VUE_APP_IS_TOOL === "true" ? "https://api.adviral.link/contest" : "https://api.adviral.io/contest",
    // API_URL: "http://localhost:1337/contest",
};
