<template>
  <div class="">
    <!-- <base-table-filter
      v-if="showFilters"
      :columns="columns"
      :apply="_onFilterChange"
      :clear="_clearFilters"
    ></base-table-filter> -->
        <div v-if="selectedItems.length" class="mt-2">
          <span class="mr-2 title">Selected Rows: {{ selectedItems.length }}</span>
          <slot name="globalControl" :items="selectedItems" >
          </slot>  
        </div>

    <table class="table tablesorter" :class="tableClass">
      <thead :class="theadClasses">
      <tr>
        <slot name="columns">
            <th
              v-for="column in columns"
              :key="column.name"
              @click="setSortBy(column)"
              style="cursor: pointer"
              :style="{'min-width': column.minWidth }"
            >
              <base-input
                  v-if="column.name == 'select'"
                  style="margin-bottom: 0px; max-width: 15px;"
                  :key="'all'"
                  :onChange="(val) => onSelectChange('all', val)"
                  type="checkbox"
              ></base-input>
              <div class="row align-items-center ml-0" v-else>
                <span>{{ column.name }}</span>
                <i
                  v-if="sortBy == getSortByName(column)"
                  class="tim-icons ml-2"
                  :class="{
                    'icon-minimal-down': sort == -1,
                    'icon-minimal-up': sort == 1,
                  }"
                ></i>
              </div>
            </th>
          </slot>
      </tr>
      </thead>
      <tbody :class="tbodyClasses">
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <td v-for="(column, index) in columns"
              :key="index"
              v-if="hasValue(item, column)"
            >
              <div v-if="column.name === 'control'" class="row justify-content-start px-0 mx-0" style="gap: 5px;">
                <slot name="control" :item="item"></slot>  
              </div>

              <base-input 
                :onChange="(val) => onSelectChange(item._id, val)"
                :key="item._id"
                v-else-if="column.name == 'select'" 
                :value="selected[item._id]"
                type="checkbox"
              >
              </base-input>


              <img v-else-if="column.type === 'image'" style="width: 50px; height: 50px" :src="itemValue(item, column)" />
              
              <span v-else-if="column.type === 'html'" style="word-break: break-word" v-html="itemValue(item, column)">
              </span>
  
              <span v-else style="word-break: break-word; min-width: 70px">
                {{ itemValue(item, column) }}
                <i class="tim-icons icon-single-copy-04 cursor-pointer" v-if="column.withCopy" @click="copy(item.id)"></i>
              </span>
          </td>
        </slot>
      </tr>
      </tbody>
      
    </table>
    <div v-if="data?.length > 0 && !loading && showPagination">
        <new-pagination
          :totalItems="totalItems"
          :currentPage="currentPage"
          :perPage="perPage"
          :onSelect="_selectPage"
          :onPerPageChange="_onPerPageChange"
        ></new-pagination>
        
    </div>
    <div v-else-if="showPagination && loading" style="height: 40px; width: 100%;" class="row justify-content-center align-items-center">
      <clip-loader color="#C153EC" size="40px" :loading="loading"></clip-loader>
    </div>
    <div v-else-if="showPagination" class="row justify-content-center">
      <span>No results found</span>
    </div>
  </div>
</template>
<script>
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
  import NewPagination from './NewPagination.vue'
  // import BaseTableFilter from './BaseTableFilter.vue';
import moment from 'moment';
import { copyToClipboard } from '../services/utils';
import BaseInput from "./Inputs/BaseInput.vue";

  export default {
    name: 'base-table',
    components: {
      ClipLoader,
      NewPagination,
      BaseInput
      // BaseTableFilter
    },
    props: {
      columns: {
        type: Array,
        default: () => [],
      },
      data: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String, // striped | hover
        default: "",
      },
      theadClasses: {
        type: String,
        default: '',
      },
      tbodyClasses: {
        type: String,
        default: '',
      },
      totalItems: Number,
      onPageChange: Function,
      onSelect: Function,
      loading: Boolean,
      defaultPerPage: Number,
      showFilters: {
        type: Boolean,
        default: true
      },
      showPagination: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        currentPage: 1,
        perPage: 50,
        sortBy: "createdAt",
        sort: -1,
        filter: {},
        selected: {},
        allSelected: false
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      },
      totalPages() {
        // console.log('---totalPages', this.totalItems,this.perPage, Math.ceil(this.totalItems / this.perPage));
        return Math.ceil(this.totalItems / this.perPage)
      },
      selectedItems() {
        const selected = [];
        for (const key in this.selected) {
            const element = this.selected[key];
        // console.log('----selectedItems', element, key);

            if (element === true) selected.push(key)
        }
        // console.log('----selectedItems', selected);
        return selected
      }
    },
    methods: {
      hasValue(item, column) {
        return item[column.name?.toLowerCase()] !== "undefined";
      },
      itemValue(item, column) {
        if (column.renderer) {
          return column.renderer(item)
        }
        return item[column.name?.toLowerCase()];
      },
      setSortBy(column) {
        if (column.name === "select") return;
        // console.log("---clicked", column);
        this.sortBy = this.getSortByName(column);
        this.sort = !this.sort || this.sort == -1 ? 1 : -1;
        // console.log("-----this.sortBy", this.sortBy, this.sort);
        this.currentPage = 1
        this.onPageChange(this.currentPage, this.perPage, this.sortBy, this.sort, this.filter)
      },
      getSortByName(column) {
        return column.sortBy || column.name;
      },
      _onPerPageChange: function (val) {
      // console.log('-onPerPageChange', val)
        if (!isNaN(val)) {
          this.perPage = val;
          this.currentPage = 1
          this.onPageChange(1, val, this.sortBy, this.sort)
        } 
      },
      _selectPage: async function (page) {
        // console.log('---select page', page, this.totalPages);
        if (page > this.totalPages || page < 1) return;
        this.currentPage = page
        this.onPageChange(page, this.perPage, this.sortBy, this.sort)

      },
      _onFilterChange: function(column, op, val) {
        // console.log('---key', {column, op, val});
        let value = val.trim();
        // console.log('---value', value);
        if (column.type === "date") {
          value = moment(val)
        }
        this.filter = {[column.key]: `${op}__${value}`}
        // console.log('---filter', this.filter);
        this.onPageChange(this.currentPage, this.perPage, this.sortBy, this.sort, this.filter)
      },
      _clearFilters() {
        this.filter = {};
        this.onPageChange(this.currentPage, this.perPage, this.sortBy, this.sort, this.filter)
        
      },
      copy(value) {
        copyToClipboard(value)
        this.$notify({
            message: "Id Successfully Copied",
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 3000,
        });
      },
      onSelectChange(item_id, val) {
        // console.log('---onSelectChange', item_id, val);
        if (item_id === "all") {
          // this.allSelected = val
          const newSelected = {}
          this.data.forEach(item => {
            newSelected[item._id] = val
          });
          this.selected = {...newSelected}
        } else {
          // this.selected[item_id] = val
          if (this.selected[item_id] !== val) {
            this.selected = {
              ...this.selected,
              [item_id]: val
            }
          }

        }

        // console.log('---this.selected after', this.selected);
      }
    },
  };
</script>
<style>
</style>
