<template>
    <div
        class="form-group"
        :class="{
            'input-group': hasIcon,
            'input-group-focus': focused,
        }"
    >
        <slot name="label">
            <label v-if="label" class="control-label" :class="{ bold: source == 'offer' }">
                {{ label }}<span v-if="required" style="color: red;"> *</span>
            </label>
        </slot>
        <slot name="addonLeft">
            <span v-if="addonLeftIcon" class="input-group-prepend">
                <div class="input-group-text">
                    <i :class="addonLeftIcon"></i>
                </div>
            </span>
        </slot>
        <slot>
            <input
                v-model="modelValue"
                :value="value"
                v-bind="$attrs"
                v-on="listeners"
                class="form-control"
                aria-describedby="addon-right addon-left"
                :type="currentType"
            />
            <div v-if="['password', 'confirm password', 'new password'].includes(label?.toLowerCase())" style="position: absolute; top: 32px; right: 10px; cursor: pointer;" @click="toggleShowPassword" >
                <!-- <i class="tim-icons icon-watch-time" style="font-size: 20px;"></i> -->
                <svg :fill="theme === 'black' ? 'white' : 'black'" xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 576 512">
                    <path d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"/>
                </svg>
            </div>
            <div v-if="label == 'Phone Number' && modelValue" style="position: absolute; top: 34px; left: 10px; cursor: pointer;" @click="toggleShowPassword" >
                <span style="margin-right: 20px; font-weight: 400;" :style="{color: theme == 'black' ? 'white' : 'black'}">+</span>
            </div>
        </slot>
        <slot name="addonRight">
            <span v-if="addonRightIcon" class="">
                <div class="input-group-text">
                    <i :class="addonRightIcon"></i>
                </div>
            </span>
        </slot>
        <slot name="helperText"
            ><small>{{ helperText }}</small></slot
        >
    </div>
</template>
<script>
export default {
    inheritAttrs: false,
    name: "base-input",
    props: {
        label: {
            type: String,
            description: "Input label",
        },
        value: {
            type: [String, Number, Boolean],
            description: "Input value",
        },
        addonRightIcon: {
            type: String,
            description: "Input icon on the right",
        },
        addonLeftIcon: {
            type: String,
            description: "Input icon on the left",
        },
        onChange: Function,
        helperText: {
            type: String,
        },
        source: String,
        type: {
            type: String,
            default: "text"
        },
        required: {
            type: Boolean
        }
    },
    model: {
        prop: "value",
        event: "input",
    },
    data() {
        return {
            focused: false,
            modelValue: null,
            isPasswordShowed: false,
            theme: localStorage.getItem("theme")
        };
    },
    created() {
        this.modelValue = this.value;
    },
    computed: {
        hasIcon() {
            const { addonRight, addonLeft } = this.$slots;
            return (
                addonRight !== undefined ||
                addonLeft !== undefined ||
                this.addonRightIcon !== undefined ||
                this.addonLeftIcon !== undefined
            );
        },
        listeners() {
            return {
                ...this.$listeners,
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus,
            };
        },
        currentType() {
            return this.isPasswordShowed ? 'text' : (this.type || 'text')
        }
    },
    methods: {
        onInput(evt) {
            this.$emit("input", evt.target.value);
        },
        onFocus() {
            this.focused = true;
        },
        onBlur() {
            this.focused = false;
        },
        toggleShowPassword() {
            this.isPasswordShowed = !this.isPasswordShowed
        }
    },
    watch: {
        modelValue(val) {
            // console.log('----modelValue', this.value, val, this.onChange);
            if (this.onChange ) {
                this.onChange(val);
            }
        },
        value(val) {
            // console.log('----value', val);
            this.modelValue = val;
        },
    },
};
</script>
<style></style>
