import Vue from "vue";
import Config from "../config.js";

var Api = {};

Api.resetPassword = function (data) {
    return Vue.axios.post(Config.API_URL + "/client/password/reset", data);
};
Api.verify = function (data) {
    return Vue.axios.post(Config.API_URL + "/client/password/reset/verify", data);
};
Api.updatePassword = function (data) {
    return Vue.axios.post(Config.API_URL + "/client/password/update", data);
};
Api.updateUser = function (data, token) {
    return Vue.axios.patch(Config.API_URL + "/client", data, {
        headers: { authorization: "bearer " + token },
    });
};
Api.getUser = function (token) {
    return Vue.axios.get(Config.API_URL + "/client", {
        headers: { authorization: "bearer " + token },
    });
};
Api.countOffers = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/offers/all/count", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getOffers = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/offers/all", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getOffersDemos = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/offers/demo", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.updateOffer = function (data, token) {
    return Vue.axios.patch(Config.API_URL + `/offers`, data, {
        headers: { authorization: "bearer " + token },
    });
};
Api.getContestEntries = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/contests/entries", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getContests = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/contests", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getThemes = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/contests/themes", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getPayments = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/payments", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getPaymentHistory = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/payments/orders", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.savePaymentMethod = function (data, token) {
    return Vue.axios.patch(Config.API_URL + "/client/payment", data, {
        headers: { authorization: "bearer " + token },
    });
};
Api.getContest = function (id, token) {
    return Vue.axios.get(Config.API_URL + `/contests/${id}`, {
        headers: { authorization: "bearer " + token },
    });
};
Api.getPublicContest = function (data, token) {
    return Vue.axios.get(Config.API_URL + `/contests/${data.id}/public`, {
        withCredentials: true,
        params: data,
    });
};
Api.getPublicContestOffers = function (data, token) {
    return Vue.axios.get(Config.API_URL + `/contests/${data.id}/public/offers`, {
        withCredentials: true,
        params: data,
    });
};
Api.verifyAction = function (data, token) {
    return Vue.axios.get(Config.API_URL + `/contests/${data.contest}/verify`, {
        withCredentials: true,
        params: data,
    });
};
Api.createContest = function (data, token) {
    // console.log("---token", token);
    return Vue.axios.post(Config.API_URL + `/contests`, data, {
        headers: { authorization: "bearer " + token },
    });
};
Api.updateContest = function (data, token) {
    return Vue.axios.patch(Config.API_URL + `/contests`, data, {
        headers: { authorization: "bearer " + token },
    });
};
Api.deleteContest = function (id, token) {
    return Vue.axios.delete(Config.API_URL + `/contests/${id}`, {
        headers: { authorization: "bearer " + token },
    });
};
Api.getConversions = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/conversions", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getClicks = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/clicks", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getViolations = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/violations", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.updateConversion = function (data, token) {
    return Vue.axios.patch(Config.API_URL + `/conversions`, data, {
        headers: { authorization: "bearer " + token },
    });
};
Api.sendCallback = function (id, token) {
    return Vue.axios.post(Config.API_URL + `/conversions/${id}`, {}, { headers: { authorization: "bearer " + token } });
};
Api.getOrders = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/orders", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.updateOrder = function (data, token) {
    return Vue.axios.patch(Config.API_URL + `/orders`, data, {
        headers: { authorization: "bearer " + token },
    });
};
// Api.getUsers = function (params, token) {
//     return Vue.axios.get(Config.API_URL + "/users", {
//         params,
//         headers: { authorization: "bearer " + token },
//     });
// };
// Api.updateUsers = function (data, token) {
//     return Vue.axios.patch(Config.API_URL + `/users`, data, {
//         headers: { authorization: "bearer " + token },
//     });
// };
Api.getVisitor = function (id, token) {
    return Vue.axios.get(Config.API_URL + `/users/${id}`, {
        headers: { authorization: "bearer " + token },
    });
};
Api.getDailyStats = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/stats/daily", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getReports = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/stats/reports", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getReferralReports = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/referral/stats", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getReferralSummary = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/referral/summary", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getGlobalStats = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/stats/global", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getAlerts = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/alerts", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getViolators = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/violations/advertisers", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getRewards = function (params, token) {
    return Vue.axios.get(Config.API_URL + "/rewards", {
        params,
        headers: { authorization: "bearer " + token },
    });
};
Api.getReward = function (id, token) {
    return Vue.axios.get(Config.API_URL + `/rewards/${id}`, {
        headers: { authorization: "bearer " + token },
    });
};
Api.createReward = function (data, token) {
    return Vue.axios.post(Config.API_URL + `/rewards`, data, {
        headers: { authorization: "bearer " + token },
    });
};
Api.updateReward = function (data, token) {
    return Vue.axios.patch(Config.API_URL + `/rewards`, data, {
        headers: { authorization: "bearer " + token },
    });
};
Api.deleteReward = function (id, token) {
    return Vue.axios.delete(Config.API_URL + `/rewards/${id}`, {
        headers: { authorization: "bearer " + token },
    });
};
Api.getSettings = function (token) {
    return Vue.axios.get(Config.API_URL + `/settings`, {
        headers: { authorization: "bearer " + token },
    });
};
Api.addParticipantEmail = function (data, token) {
    return Vue.axios.post(Config.API_URL + `/auth/login/email?contest=${data.contest}`, data, {
        withCredentials: true,
    });
};
Api.addParticipantProvider = function (data, token) {
    return Vue.axios.post(Config.API_URL + `/auth/login?contest=${data.contest}`, data, {
        withCredentials: true,
    });
};
Api.setContestWinner = function (data, token) {
    return Vue.axios.post(Config.API_URL + `/contests/${data.contest}/winner`, data, {
        withCredentials: true,
    });
};
Api.getContestParticipants = function (params, token) {
    return Vue.axios.get(Config.API_URL + `/contests/${params.contest}/participants`, {
        params,

        withCredentials: true,
    });
};
Api.getHelp = function (params, token) {
    return Vue.axios.get(Config.API_URL + `/help`, {
        headers: { authorization: "bearer " + token },
    });
};
Api.isReferrerValid = function (params, token) {
    return Vue.axios.get(Config.API_URL + `/client/verify`, {
        params,
        headers: { authorization: "bearer " + token },
    });
};

export default Api;
