import { render, staticRenderFns } from "./Twitch.vue?vue&type=template&id=383577b9&scoped=true"
import script from "./Twitch.vue?vue&type=script&lang=js"
export * from "./Twitch.vue?vue&type=script&lang=js"
import style0 from "./Twitch.vue?vue&type=style&index=0&id=383577b9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "383577b9",
  null
  
)

export default component.exports