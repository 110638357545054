<template>
    <div
        class="contest-card-container"
        style=" border-radius: 0px; color: #666666 !important"
    >
        <login
            :model="loginModel"
            :loading="loginLoading"
            :show="showLoginModal"
            :close="() => controlShowLoginModal(false)"
            :save="loginWithEmail"
            :error="errors.addParticipantEmail"
            :buttonColor="buttonColor"
        >
        </login>
        <history-table
            :show="showHistory"
            :close="() => controlShowHistory(false)"
            :currentPage="1"
            :perPage="100"
            :data="table1.data"
            :columns="table1.columns"
            thead-classes="text-primary"
        >
        </history-table>
        <complete-offer-notice
            :show="showOfferNotice"
            :close="() => controlShowOfferNotice(false)"
        >
        </complete-offer-notice>
        <username-popup
            :show="selectedActionPopup !== null"
            :close="() => hideShowUsername()"
            :openAction="verifyAction"
            :item="selectedActionPopup"
            :buttonColor="buttonColor"
        >
        </username-popup>
        <div
            v-if="loading"
            style="background-color: white; min-height: 90vh;"
            class="row justify-content-center align-items-center"
        >
            <!-- <clip-loader color="#19BC9B" size="100px" :loading="loading"></clip-loader> -->
            <div class="loading-logo">
                <img 
                    src="/img/logo-adviral.png"
                    style="width: 100px"
                />
            </div>
        </div>
        <div
            @scroll="handleScroll"
            v-if="!loading"
            style="padding: 0px; overflow-y: scroll"
            :style="{ height: isEnded || winnerExist ? 'none' : '97vh' }"
        >
            <div class="row justify-content-between contest-card-header"
            :style="{background: contest?.main_color}"
            
            >
                <!-- <img
                    src="https://main-p.agmcdn.com/YoyZl2HZHjmH7JH1dweFyLfrrb6Ab3Vxo6JTV6uA.webp"
                    style="max-width: 60px"
                /> -->
                <div class="col-4">
                    <span style="color: white; font-size: 18px; font-weight: bold;" class="mr-2">{{ participant?.entries }}</span>
                    <i class="fas fa-ticket-alt" style="color: white; font-size: 18px;"></i>
                </div>
                <!-- <div class="col-4 row justify-content-center align-items-center"><span
                    style="color: #FFF;
                    font-size: 21px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;"
                >Logo</span></div> -->
                <div>
                    <img 
                        src="/img/logo-adviral.png"
                        style="width: 60px"
                    />
                </div>
                <div class="col-4 justify-content-end row pr-4 align-items-center cursor-pointer" @click="showHistory = true"><img style="width: 20px" src="../../assets/images/history-icon.png" /></div>
            </div>
            <contest-timer :contest="contest" :isEnded="isEnded" :showCountdown="showCountdown"></contest-timer>

            <div class="my-2" v-if="(!isEnded && !winnerExist) || (winnerExist && isEnded)">
                <video style="width: 100%" controls v-if="contest?.media?.link && isVideo" autoplay="true" muted>
                    <source :src="contest?.media?.link" />
                </video>
                <contest-media v-else-if="contest?.media?.link" :link="contest?.media?.link" ></contest-media>
                <div class="px-4 my-2">
                    <h3 class="mb-0"
                    :style="{'font-size': `${contest?.title_font_size || '24'}px`}"
                    style="
                        color: #333 !important;
                        font-family: Open Sans;
                        text-align: center;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        letter-spacing: 0.8px;
                        "
                    >{{ contest?.title }}</h3>
                    <div class="my-0 contest-description" style="color: #333;
                        font-family: Open Sans;
                        font-size: 16.175px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;" v-if="contest?.description ">
                        <span style="font-weight: 400; letter-spacing: 0.1px; white-space: pre-wrap;">{{ contest?.description }}</span>
                    </div>

                </div>
            </div>

            <div v-if="isEnded && !winnerExist">
                <div
                    class="mx-0 my-2 py-3 justify-content-center align-items-center my-2 contest-login"
                    style="background-color: #eceff1"
                >
                    <span class="mr-2" style="font-size: 18px">The winning entries will be chosen </span>
                </div>
                <img src="../../assets/images/contest_ended.jpeg" />
            </div>

            <div v-if="winnerExist">
                <div
                    class="mx-0 my-2 py-3 justify-content-center align-items-center my-2 contest-login"
                    style="background-color: #eceff1"
                >
                    <span class="mr-2" style="font-size: 18px">Winning entries</span>
                </div>
                <div class="px-2" >
                    <div
                    v-for="(winner, index) in contest.winners"
                        class="col-md-12 row align-items-center py-3 justify-content-between mt-2 mb-2"
                        :style="{ cursor: 'pointer' }"
                        style="background-color: #eceff1; border-radius: 5px"
                    >
                        <div class="mr-4 px-0 row justify-content-center align-items-center">
                            <i class="tim-icons icon-single-02" style="font-size: 40px"></i>
                        </div>
                        <div class="col-8 px-0">
                            <!-- <img src="../../assets/images/facebook.png" /> -->
                            <a style="font-size: 16px; font-weight: 600">{{
                                winner?.name || winner?.user_id
                            }}</a>
                        </div>
                        <div class="col-2 contest-action-button my-0 text-center">
                            <h4 style="color: white !important" class="mb-0 text-center">#{{index + 1}}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <contest-login
                :controlShowLoginModal="controlShowLoginModal"
                :handleFacebookLogin="handleFacebookLogin"
                :scope="scope"
                :contest="contest"
                :participant="participant"
                :Logout="Logout"
                :isEnded="isEnded"
                :buttonColor="buttonColor"
        ></contest-login>

            <div v-if="!winnerExist && !isEnded">
                <div class="row justify-content-center contest-tabs my-3" v-if="contest.is_social_active">
                    <div
                        @click="setTab('tasks')"
                        class="contest-tab col-md-6 col-6"
                        :style="{background: tab === 'tasks' ? contest?.main_color: '#ECEFF1'}"
                        :class="{ 'contest-tab-active': tab === 'tasks' }"
                    >
                        <span>Play To Earn</span>
                    </div>
                    <div
                        @click="setTab('social')"
                        class="contest-tab col-md-6 col-6"
                        :style="{background: tab === 'social' ? contest?.main_color: '#ECEFF1'}"
                        :class="{ 'contest-tab-active': tab === 'social' }"
                    >
                        <span>Social Shares</span>
                    </div>
                </div>

                <div class="align-items-center contest-sub-tabs mb-3"
                    :class="{'mt-3': !contest.is_social_active}" 
                style="display: flex;
    flex-direction: column;" ref="target" v-if="tab === 'tasks'" id="tasks-tab">
                        <div
                            @click="showSort()"
                            v-click-outside="() => handleOutsideClicks('sort')"
                            class="contest-sub-tab row justify-content-between"
                            style="position: relative"
                            :class="{ 'contest-sub-tab-open': isShowSort }"
                            :style="{ 'border-bottom-width': isShowSort ? '0px' : undefined }"
                        >
                        <span></span>
                            <span class="mr-2">{{ sortOption.title }}</span
                            >
                            
                            <div><i
                                style="float: right; font-size: 15px; margin-top: 4px; position: absolute"
                                class="tim-icons icon-minimal-down"
                            ></i></div>
                        </div>
                        <div
                            v-for="(option, optionIndex) in sortOptions.filter((x) => x.value !== sortOption.value)"
                            class="contest-sub-tab text-center py-2"
                            :style="{ 'border-bottom-width': isShowSort && optionIndex == 1 ? '1.5px' : '0px' }"
                            style="border-radius: 0px; border-bottom-width: 0px"
                            v-if="isShowSort"
                            @click="setSort(option)"
                        >
                            <span>{{ option.title }}</span>
                        </div>
                </div>

                <div class="contest-card-actions mx-0" v-if="tab === 'tasks' && !loadingSort">
                    <div
                        v-for="offer in sortedOffers"
                        class="contest-card-action row justify-content-between align-items-center px-0 py-2"
                    >
                        <div
                            class="col-md-12 row align-items-center"
                            style="cursor: pointer"
                            @click="switchOfferDetails(offer._id)"
                        >
                            <div class="col-2 px-0 row justify-content-center align-items-center">
                                <img :src="offer.logo" style="width: 55px; cursor: pointer" />
                            </div>
                            <div class="col-7 px-0 pl-2" style="flex-direction: column">
                                <a style="font-size: 16px; font-weight: 600; color: unset, cursor: pointer;"
                                    ><span style="color: #000;
                                            font-size: 15px;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: 19.975px;">{{ displayName(offer.name) }}</span></a
                                >
                                <div style="word-break: break-word" class="hide-mobile">
                                    <svg
                                        v-if="offer.devices?.includes('android')"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#A0A0A0"
                                        class="bi bi-android2 mr-1"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="m10.213 1.471.691-1.26c.046-.083.03-.147-.048-.192-.085-.038-.15-.019-.195.058l-.7 1.27A4.832 4.832 0 0 0 8.005.941c-.688 0-1.34.135-1.956.404l-.7-1.27C5.303 0 5.239-.018 5.154.02c-.078.046-.094.11-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.697 3.697 0 0 0 3.5 5.02h9c0-.75-.208-1.44-.623-2.072a4.266 4.266 0 0 0-1.664-1.476ZM6.22 3.303a.367.367 0 0 1-.267.11.35.35 0 0 1-.263-.11.366.366 0 0 1-.107-.264.37.37 0 0 1 .107-.265.351.351 0 0 1 .263-.11c.103 0 .193.037.267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264Zm4.101 0a.351.351 0 0 1-.262.11.366.366 0 0 1-.268-.11.358.358 0 0 1-.112-.264c0-.103.037-.191.112-.265a.367.367 0 0 1 .268-.11c.104 0 .19.037.262.11a.367.367 0 0 1 .107.265c0 .102-.035.19-.107.264ZM3.5 11.77c0 .294.104.544.311.75.208.204.46.307.76.307h.758l.01 2.182c0 .276.097.51.292.703a.961.961 0 0 0 .7.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182c0 .276.097.51.292.703a.972.972 0 0 0 .71.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76c.291 0 .54-.103.749-.308.207-.205.311-.455.311-.75V5.365h-9v6.404Zm10.495-6.587a.983.983 0 0 0-.702.278.91.91 0 0 0-.293.685v4.063c0 .271.098.501.293.69a.97.97 0 0 0 .702.284c.28 0 .517-.095.712-.284a.924.924 0 0 0 .293-.69V6.146a.91.91 0 0 0-.293-.685.995.995 0 0 0-.712-.278Zm-12.702.283a.985.985 0 0 1 .712-.283c.273 0 .507.094.702.283a.913.913 0 0 1 .293.68v4.063a.932.932 0 0 1-.288.69.97.97 0 0 1-.707.284.986.986 0 0 1-.712-.284.924.924 0 0 1-.293-.69V6.146c0-.264.098-.491.293-.68Z"
                                        />
                                    </svg>
                                    <svg
                                        v-if="offer.devices?.includes('ios')"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#A0A0A0"
                                        class="bi bi-apple"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"
                                        />
                                        <path
                                            d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div class="col-3 contest-action-button my-0 px-0" style="max-height: 40px;" :style="{background: buttonColor}">
                                <h4 style="color: white !important; cursor: pointer; font-size: 14.5px;" class="mb-0 text-center">
                                    {{ renderOfferPayout(offer.payout) }} <i class="fas fa-ticket-alt ml-1" style="color: white; font-size: 14px;"></i>
                                </h4>
                            </div>
                        </div>

                        <div class="col-md-12 my-2 text-center" v-if="showOfferDetails === offer._id" :id="offer._id">
                            <div>
                                <h4 style="font-weight: 600; color: #333333 !important;" class="mb-0">Instructions:</h4>
                                {{ formatInstructions(offer.instructions) }}
                            </div>

                            <restriction :mainColor="contest.main_color"></restriction>
                            
                            <div class="qrcode-container mt-2">
                                <h4 style="font-weight: 600; color: #333333 !important" class="mb-0">Send link to your device:</h4>
                                <div class="mr-4">Scan the QR code with your smartphone or tablet.</div>
                                <qrcode-vue :size="200" level="H" class="mt-2" :value="offer.link" />
                                <div class="row qrcode"></div>
                            </div>
                            
                            <base-button class="mt-4 hide-mobile" :style="{background: buttonColor}" @click="copyLink(offer.link)"
                                ><i
                                    v-if="linkCopied"
                                    style="margin-right: 5px; margin-bottom: 4px"
                                    class="tim-icons icon-check-2"
                                ></i
                                >{{ linkCopied ? "Link copied" : "Copy link" }}</base-button
                            >
                            <base-button
                                class="mt-4 show-mobile"
                                style="margin: 0 auto;font-size: 19px;"
                                :style="{background: buttonColor}"
                                @click="visitOffer(offer.link)"
                                >
                                +{{ renderOfferPayout(offer.payout) }}
                                <i class="fas fa-ticket-alt ml-1" style="color: white; font-size: 18px;"></i>
                                </base-button
                            >
                        </div>
                    </div>
                </div>

                <div
                    style="background-color: white"
                    class="row justify-content-center align-items-center my-2 mt-4"
                >
                    <clip-loader color="#19BC9B" size="50px" :loading="loadingSort || loadingOffers"></clip-loader>
                </div>

                <div class="contest-card-actions mx-0" v-if="tab === 'social' && contest.is_social_active">
                    <div
                        v-for="item in contest.actions"
                        class="contest-card-action row justify-content-between align-items-center px-1 py-2"
                    >
                        <div
                            class="col-md-12 row align-items-center"
                            :style="{ cursor: !item.done && 'pointer' }"
                            @click="switchOfferDetails(item._id, item.done, 'action')"
                        >
                            <div class="col-2 px-0 row justify-content-center align-items-center">
                                <div 
                                    v-if="item.key.includes('facebook')"
                                >
                                    <i class="fab fa-facebook facebook-icon" style=""></i>
                                </div>
                                <img
                                    v-if="item.key.includes('twitter')"
                                    src="../../assets/images/twitter.png"
                                    style="width: 80%"
                                />
                                <img
                                    v-if="item.key.includes('instagram')"
                                    src="../../assets/images/instagram.png"
                                    style="width: 80%"
                                />
                                <img
                                    v-if="item.key.includes('tiktok')"
                                    src="../../assets/images/tiktok.png"
                                    style="width: 80%"
                                />
                                <div 
                                    v-if="item.key.includes('youtube')"
                                >
                                    <i class="fab fa-youtube youtube-icon" style=""></i>
                                </div>
                                <div 
                                    v-if="item.key.includes('discord')"
                                >
                                    <i class="fab fa-discord discord-icon" style="color: rgb(88, 101, 242)"></i>
                                </div>
                                <div 
                                    v-if="item.key.includes('twitch')"
                                >
                                    <i class="fab fa-twitch youtube-icon" style="color: #9146FF;"></i>
                                </div>
                                <div 
                                    v-if="item.key.includes('telegram')"
                                >
                                    <i class="fab fa-telegram youtube-icon" style="color: rgb(0, 136, 204);"></i>
                                </div>
                                <!-- <img
                                    
                                    src="../../assets/images/youtube.png"
                                    style="width: 80%"
                                /> -->
                            </div>
                            <div class="col-7 px-0">
                                <!-- <img src="../../assets/images/facebook.png" /> -->
                                <a style="font-size: 16px; font-weight: 600" :href="item.value">{{ item.title }}</a>
                            </div>
                            <div class="col-3 contest-action-button my-0 text-center" :style="{background: buttonColor}">
                                <i v-if="item.done" class="tim-icons icon-check-2" style="color: white"></i>
                                <h4 v-else style="color: white !important" class="mb-0 text-center">
                                    {{ item.amount }}  <i class="fas fa-ticket-alt ml-1" style="color: white; font-size: 14px;"></i>
                                </h4>
                            </div>
                        </div>
                        <div class="col-md-12 my-2 text-center" v-if="showOfferDetails === item._id" :id="item._id">
                            <div v-if="item.key === 'twitter-view'">
                                <blockquote class="twitter-tweet">
                                    <a :href="item.inputs[0]?.value">Loading tweet...</a>
                                </blockquote>
                                <TwitterFeed :src="item.inputs[0]?.value"></TwitterFeed>

                                <!-- <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> -->
                                <!-- <a
                                    class="twitter-tweet"
                                    href="https://twitter.com/dontsaysuggs/status/1694781540637532556?ref_src=twsrc%5Etfw"
                                    >Tweet</a
                                > -->

                                <base-button class="mt-4" :style="{background: buttonColor}"  @click="openUsernamePopup(item)"
                                    >Complete Task</base-button
                                >
                            </div>
                            <div v-if="item.key === 'twitter-follow'">
                                <div>
                                    <a
                                        :href="`https://twitter.com/${item.inputs[0]?.value}?ref_src=twsrc%5Etfw`"
                                        class="twitter-follow-button"
                                        data-show-count="false"
                                        data-size="large"
                                        >Follow @{{ item.inputs[0]?.value }}</a
                                    >
                                </div>

                                <div style="display: none">
                                    <TwitterFeed :src="item.inputs[0]?.value" style="display: none"></TwitterFeed>
                                </div>
                                <div class="mt-4" style="cursor: pointer" @click="openUsernamePopup(item)">
                                    <span style="text-decoration: underline">Already followed?</span>
                                </div>
                            </div>
                            <div v-if="item.key === 'twitter-share'">
                                <div>
                                    <a
                                        :href="`https://twitter.com/share?ref_src=twsrc%5Etfw&text=${item.inputs[0]?.value}`"
                                        class="twitter-share-button"
                                        data-size="large"
                                        data-show-count="false"
                                        >Tweet</a
                                    >
                                    <div style="display: none">
                                        <TwitterFeed :src="item.inputs[0]?.value" style="display: none"></TwitterFeed>
                                    </div>
                                </div>
                                <div class="mt-4" style="cursor: pointer" @click="openUsernamePopup(item)">
                                    <span style="text-decoration: underline">Already shared?</span>
                                </div>
                            </div>
                            <div v-if="item.key === 'twitter-retweet'">
                                <div>
                                    <a
                                        :href="`https://twitter.com/intent/retweet?tweet_id=${extractTweetId(
                                            item.inputs[0]?.value,
                                        )}`"
                                        target="_blank"
                                        class="twitter-button"
                                    >
                                        <svg style="margin-right: 8px" viewBox="0 0 24 24" width="18" height="18">
                                            <g>
                                                <path
                                                    fill="#ffffff"
                                                    d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.404-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 2-5.786 2-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"
                                                ></path>
                                            </g>
                                        </svg>
                                        Retweet
                                    </a>
                                    <div style="display: none">
                                        <TwitterFeed :src="item.inputs[0]?.value" style="display: none"></TwitterFeed>
                                    </div>
                                </div>
                                <div class="mt-4" style="cursor: pointer" @click="openUsernamePopup(item)">
                                    <span style="text-decoration: underline">Already retweeted?</span>
                                </div>
                            </div>
                            <div v-if="item.key === 'twitter-comment'">
                                <div>
                                    <a
                                        :href="`https://twitter.com/intent/tweet?in_reply_to=${extractTweetId(
                                            item.inputs[0]?.value,
                                        )}`"
                                        target="_blank"
                                        class="twitter-button"
                                    >
                                        <svg class="comment-icon" viewBox="0 0 24 24" width="18" height="18">
                                            <g>
                                                <path
                                                    fill="#ffffff"
                                                    d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.404-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 2-5.786 2-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"
                                                ></path>
                                            </g>
                                        </svg>
                                        Comment
                                    </a>
                                    <div style="display: none">
                                        <TwitterFeed :src="item.inputs[0]?.value" style="display: none"></TwitterFeed>
                                    </div>
                                </div>

                                <div class="mt-4" style="cursor: pointer" @click="openUsernamePopup(item)">
                                    <span style="text-decoration: underline">Already commented?</span>
                                </div>
                            </div>
                            <div v-if="item.key === 'instagram-follow'">
                                <div>
                                    <a
                                        :href="`https://www.instagram.com/${item.inputs[0]?.value}`"
                                        target="_blank"
                                        class="instagram-button"
                                    >
                                        <svg
                                            aria-label="Instagram"
                                            class="icon"
                                            viewBox="0 0 448 512"
                                            width="20"
                                            height="20"
                                        >
                                            <path
                                                fill="white"
                                                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9-2.1 147.9 0 184.9 9.9 67.7 36.2 93.9 58 34.4 93.9 36.2 147.9 2.1 184.9 0c35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 .1-147.9 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                            ></path>
                                        </svg>

                                        Follow
                                    </a>
                                </div>

                                <div class="mt-4" style="cursor: pointer" @click="openUsernamePopup(item)">
                                    <span style="text-decoration: underline">Already followed?</span>
                                </div>
                            </div>
                            <div v-if="item.key === 'instagram-comment'">
                                <div>
                                    <a :href="item.inputs[0]?.value" target="_blank" class="instagram-button">
                                        <svg
                                            aria-label="Instagram"
                                            class="icon"
                                            viewBox="0 0 448 512"
                                            width="20"
                                            height="20"
                                        >
                                            <path
                                                fill="white"
                                                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9-2.1 147.9 0 184.9 9.9 67.7 36.2 93.9 58 34.4 93.9 36.2 147.9 2.1 184.9 0c35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 .1-147.9 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                            ></path>
                                        </svg>
                                        Comment
                                    </a>
                                </div>

                                <div class="mt-4" style="cursor: pointer" @click="openUsernamePopup(item)">
                                    <span style="text-decoration: underline">Already commented?</span>
                                </div>
                            </div>
                            <div v-if="item.key === 'instagram-like'">
                                <div>
                                    <a :href="item.inputs[0]?.value" target="_blank" class="instagram-button">
                                        <svg
                                            aria-label="Instagram"
                                            class="icon"
                                            viewBox="0 0 448 512"
                                            width="20"
                                            height="20"
                                        >
                                            <path
                                                fill="white"
                                                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9-2.1 147.9 0 184.9 9.9 67.7 36.2 93.9 58 34.4 93.9 36.2 147.9 2.1 184.9 0c35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 .1-147.9 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                            ></path>
                                        </svg>
                                        Like
                                    </a>
                                </div>

                                <div class="mt-4" style="cursor: pointer" @click="openUsernamePopup(item)">
                                    <span style="text-decoration: underline">Already liked?</span>
                                </div>
                            </div>
                            <div v-if="item.key === 'instagram-share'">
                                <div>
                                    <a :href="item.inputs[0]?.value" target="_blank" class="instagram-button">
                                        <svg
                                            aria-label="Instagram"
                                            class="icon"
                                            viewBox="0 0 448 512"
                                            width="20"
                                            height="20"
                                        >
                                            <path
                                                fill="white"
                                                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9-2.1 147.9 0 184.9 9.9 67.7 36.2 93.9 58 34.4 93.9 36.2 147.9 2.1 184.9 0c35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 .1-147.9 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                            ></path>
                                        </svg>
                                        Share
                                    </a>
                                </div>

                                <div class="mt-4" style="cursor: pointer" @click="openUsernamePopup(item)">
                                    <span style="text-decoration: underline">Already shared?</span>
                                </div>
                            </div>
                            <div v-if="item.key === 'instagram-view'">
                                <div>
                                    <a :href="item.inputs[0]?.value" target="_blank" class="instagram-button">
                                        <svg
                                            aria-label="Instagram"
                                            class="icon"
                                            viewBox="0 0 448 512"
                                            width="20"
                                            height="20"
                                        >
                                            <path
                                                fill="white"
                                                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9-2.1 147.9 0 184.9 9.9 67.7 36.2 93.9 58 34.4 93.9 36.2 147.9 2.1 184.9 0c35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 .1-147.9 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                            ></path>
                                        </svg>
                                        View
                                    </a>
                                </div>

                                <div class="mt-4" style="cursor: pointer" @click="openUsernamePopup(item)">
                                    <span style="text-decoration: underline">Already viewed?</span>
                                </div>
                            </div>
                            <div v-if="item.key === 'tiktok-comment'">
                                <div>
                                    <a :href="item.inputs[0]?.value" target="_blank" class="tiktok-button">
                                        <svg
                                            fill="white"
                                            viewBox="0 0 512 512"
                                            id="icons"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                        >
                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                            <g
                                                id="SVGRepo_tracerCarrier"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            ></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path
                                                    d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"
                                                ></path>
                                            </g>
                                        </svg>
                                        Comment
                                    </a>
                                </div>

                                <div class="mt-4" style="cursor: pointer" @click="openUsernamePopup(item)">
                                    <span style="text-decoration: underline">Already commented?</span>
                                </div>
                            </div>
                            <div v-if="item.key === 'tiktok-like'">
                                <div>
                                    <a :href="item.inputs[0]?.value" target="_blank" class="tiktok-button">
                                        <svg
                                            fill="white"
                                            viewBox="0 0 512 512"
                                            id="icons"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                        >
                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                            <g
                                                id="SVGRepo_tracerCarrier"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            ></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path
                                                    d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"
                                                ></path>
                                            </g>
                                        </svg>
                                        Like
                                    </a>
                                </div>

                                <div class="mt-4" style="cursor: pointer" @click="openUsernamePopup(item)">
                                    <span style="text-decoration: underline">Already liked?</span>
                                </div>
                            </div>
                            <div v-if="item.key === 'tiktok-share'">
                                <div>
                                    <a :href="item.inputs[0]?.value" target="_blank" class="tiktok-button">
                                        <svg
                                            fill="white"
                                            viewBox="0 0 512 512"
                                            id="icons"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                        >
                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                            <g
                                                id="SVGRepo_tracerCarrier"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            ></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path
                                                    d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"
                                                ></path>
                                            </g>
                                        </svg>
                                        Share
                                    </a>
                                </div>

                                <div class="mt-4" style="cursor: pointer" @click="openUsernamePopup(item)">
                                    <span style="text-decoration: underline">Already shared?</span>
                                </div>
                            </div>
                            <div v-if="item.key === 'tiktok-follow'">
                                <div>
                                    <a
                                        :href="`https://www.tiktok.com/@${item.inputs[0]?.value}`"
                                        target="_blank"
                                        class="tiktok-button"
                                    >
                                        <svg
                                            fill="white"
                                            viewBox="0 0 512 512"
                                            id="icons"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                        >
                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                            <g
                                                id="SVGRepo_tracerCarrier"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            ></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path
                                                    d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"
                                                ></path>
                                            </g>
                                        </svg>
                                        Follow
                                    </a>
                                </div>

                                <div class="mt-4" style="cursor: pointer" @click="openUsernamePopup(item)">
                                    <span style="text-decoration: underline">Already followed?</span>
                                </div>
                            </div>
                            <youtube-card :item="item" :onConfirm="openUsernamePopup"></youtube-card>
                            <twitch-card :item="item" :onConfirm="openUsernamePopup"></twitch-card>
                            <discord-card :item="item" :onConfirm="openUsernamePopup"></discord-card>
                            <telegram-card :item="item" :onConfirm="openUsernamePopup"></telegram-card>
                            <facebook-card :item="item" :onConfirm="openUsernamePopup"></facebook-card>
                        </div>
                    </div>
                    <div v-if="contest.actions.length === 0"></div>
                </div>
            </div>
            <div
                v-if="loadingMore && !loadingSort"
                style="background-color: white"
                class="row justify-content-center align-items-center my-2"
            >
                <clip-loader color="#19BC9B" size="50px" :loading="true"></clip-loader>
            </div>
        </div>
        <div class="mb-1 mb-0 text-center px-2">
            <span class="mb-2" style="text-align: center;"><a style="color: black; text-decoration: underline;" href="https://adviral.io/terms-conditions.html" target="_blank">Terms & Conditions</a> |  Powered and sponsored by <a href="https://adviral.io" target="_blank" style="font-weight: bold;">Adviral.io</a></span>
        </div>
        <div style="display: none">
            <TwitterFeed :src="`https://twitter.com/twitter?ref_src=twsrc%5Etfw`" style="display: none"></TwitterFeed>
        </div>
    </div>
</template>
<script>
import "./contestCard.css";
import moment from "moment";
import vClickOutside from "v-click-outside";
import Login from "./Login.vue";
Vue.use(vClickOutside);
import { mapGetters } from "vuex";
// import VFBLogin from "facebook-login-vuejs";
// import VueFacebookLogin from "vue-facebook-login-component";
import { VFBLoginScope as VFacebookLoginScope } from "vue-facebook-login-component";
import QrcodeVue from "qrcode.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import config from "../../config";
import { copyToClipboard, getTweetId, decodeHtmlEntities } from "../../services/utils";
import HistoryTable from "./HistoryTable.vue";
import UsernamePopup from "./UsernamePopup.vue";
import Vue from "vue";
import TwitterFeed from "vuejs-twitter-feed";
import ContestMedia from './ContestMedia.vue'
import ContestTimer from './ContestTimer.vue'
import ContestLogin from './ContestLogin.vue'
import Restriction from './Restriction.vue'
import YoutubeCard from './SocialCards/Youtube.vue'
import DiscordCard from './SocialCards/Discord.vue'
import TwitchCard from './SocialCards/Twitch.vue'
import TelegramCard from './SocialCards/Telegram.vue'
import FacebookCard from './SocialCards/Facebook.vue'
import CompleteOfferNotice from "./CompleteOfferNotice.vue";
Vue.use(TwitterFeed);
const tableColumns = ["Title", "Type", "Status", "Amount"];

const videoExtensions = [".mp4", ".mkv", ".avi", ".mov", ".wmv", ".flv", ".webm", ".3gp", ".mpg", ".mpeg"];

export default {
    name: "contest-card",
    components: {
        Login,
        VFacebookLoginScope,
        QrcodeVue,
        ClipLoader,
        UsernamePopup,
        HistoryTable,
        ContestMedia,
        ContestTimer,
        ContestLogin,
        Restriction,
        YoutubeCard,
        DiscordCard,
        TwitchCard,
        TelegramCard,
        FacebookCard,
        CompleteOfferNotice
    },
    props: {
        contest: Object,
        demoOffers: Array,
        participant: Object,
        loading: Boolean,
        loadingOffers: Boolean,
        refreshContest: Function,
        refreshOffers: Function,
        isDemo: Boolean,
    },
    created() {
        let ckeditor = document.createElement("script");
        ckeditor.setAttribute("src", "https://platform.twitter.com/widgets.js");
        document.head.appendChild(ckeditor);
    },
    data() {
        return {
            loginModel: {
                name: null,
                email: null,
            },
            loginLoading: false,
            showLoginModal: false,
            counter: 0,
            tab: "tasks",
            sort: 1,
            isShowFilter: false,
            isShowSort: false,
            filter: "Most Popular",
            FB: {},
            scope: {},
            showOfferDetails: false,
            sortOptions: [
                { title: "High To Low", value: 1, sortBy: "payout", sort: -1 },
                { title: "Low To High", value: -1, sortBy: "payout", sort: 1 },
                { title: "Most Popular", value: "popular", sortBy: "popular", sort: -1 },
            ],
            sortOption: { title: "Most Popular", value: "popular" },
            linkCopied: false,
            table1: {
                title: "Conversions",
                columns: [...tableColumns],
                data: [],
            },
            showHistory: false,
            loadingMore: false,
            loadingSort: false,
            linkToVisitAfterFacebookLogin: null,
            selectedActionPopup: null,
            showOfferNotice: false,
            firstOfferDone: false
        };
    },
    computed: {
        buttonColor() {
            return !this.contest?.main_color || this.contest?.main_color == '#333' || this.contest?.main_color == '#333333' ? '#333333' : this.contest?.main_color
            // return !this.contest?.main_color || this.contest?.main_color == '#333' || this.contest?.main_color == '#333333' ? '#00DA48' : this.contest?.main_color
        },
        showCountdown() {
            // if ()
            const duration = moment.duration(this.counter);
            const days = this.setRightNumbers(duration.days());
            const hours = this.setRightNumbers(duration.hours());
            const minutes = this.setRightNumbers(duration.minutes());
            const seconds = this.setRightNumbers(duration.seconds());
            const formattedCountdown = `${days} : ${hours} : ${minutes} : ${seconds}`;
            
            const diff = formattedCountdown.split(" ");
            const arr = ["Days", ":", "Hours", ":", "Minutes", ":", "Seconds"].map((x, i) => {
                if (x === ":") {
                    return {name: x}
                }
                return {
                    value: diff[i].split(""),
                    name: x,
                };
            });
            return arr;
        },
        sortedOffers() {
        //     let sortBy = "payout";
        //     let sort = this.sortOption.value;
        //     if (this.sortOption.value === "popular") {
        //         sortBy = "_id";
        //         sort = 1;
        //     }
        //     const offers = this.demoOffers.sort((a, b) => {
        //         // console.log(isNaN(a?.[sortBy]), a[sortBy].localeCompare);
        //         if (sort == -1) {
        //             return isNaN(a?.[sortBy]) && a[sortBy]?.localeCompare && b[sortBy]?.localeCompare
        //                 ? a[sortBy]?.localeCompare(b[sortBy])
        //                 : a?.[sortBy] - b?.[sortBy];
        //         } else {
        //             return isNaN(a?.[sortBy]) && a[sortBy]?.localeCompare && b[sortBy]?.localeCompare
        //                 ? b[sortBy]?.localeCompare(a[sortBy])
        //                 : b?.[sortBy] - a?.[sortBy];
        //         }
        //     });
        //     console.log('----offers.lenth', offers.length);
            return this.demoOffers;
        },
        isEnded() {
            return this.contest && new Date().getTime() >= new Date(this.contest.date?.end);
        },
        isVideo() {
            for (let i = 0; i < videoExtensions.length; i++) {
                const ext = videoExtensions[i];
                if (this.contest.media?.link?.includes(ext)) return true;
            }
            return false;
        },
        winnerExist() {
            const winners = this.contest?.winners;
            if (Array.isArray(winners)) {
                return winners.length > 0
            }
            return false
        },
        ...mapGetters(["errors"]),
    },
    methods: {
        renderOfferPayout(payout) {
            const value = parseFloat(payout)
            if (value.toString().length > 6) {
                return value.toFixed(0)
            }  else {
                return value
            }
        },
        setRightNumbers(value) {
            value = `${value}`
            if (value.length < 2) {
                return `0${value}`
            }
            return value
        },
        async handleFacebookLogin(response) {
            if (response) {
                const token = response.authResponse.accessToken;
                await this.$store.dispatch("addParticipantProvider", {
                    token,
                    provider: "facebook",
                    contest: this.contest._id,
                });

                if (this.linkToVisitAfterFacebookLogin) {
                    const url = new URL(this.linkToVisitAfterFacebookLogin);
                    url.searchParams.set("user_id", this.participant.user_id);
                    try {
                        await fetch(url.href);
                    } catch (error) {
                        console.error("error", error);
                    }
                    await this.refreshContest();
                    this.linkToVisitAfterFacebookLogin = null;
                }
            }
        },
        getDiff() {
            // return moment.duration(this.counter, "seconds");
            return;
        },
        setTab(status) {
            this.tab = status;
        },
        // showFilter() {
        //     if (this.isShowFilter) {
        //         this.isShowFilter = true;
        //     }
        //     this.isShowFilter = !this.isShowFilter;
        // },
        showSort(from) {
            this.isShowSort = !this.isShowSort;
        },
        handleOutsideClicks(from) {
            setTimeout(() => {
                this.isShowSort = false;
            }, 100);
            // if (from === "sort") {
            // } else {
            //     this.isShowFilter = false;
            // }
        },
        setFilter() {
            if (this.filter === "Most Popular") {
                this.filter = "Featured";
            } else {
                this.filter = "Most Popular";
            }
        },
        async setSort(option) {
            if (option) {
                this.sortOption = option;
                this.loadingSort = true
                await this.refreshOffers("sort", option.sort, option.sortBy);
                this.loadingSort = false

            }
        },
        controlShowLoginModal(status) {
            this.showLoginModal = status;
        },
        controlShowHistory(status) {
            this.showHistory = status;
        },
        hideShowUsername() {
            this.selectedActionPopup = null;
        },
        async loginWithEmail() {
            this.loginLoading = false;
            await this.$store.dispatch("addParticipantEmail", { ...this.loginModel, contest: this.contest._id });
            this.loginLoading = true;
            this.showLoginModal = false;
            await this.refreshContest(this.sortOption.sort, this.sortOption.sortBy, true);

        },
        Logout() {
            const isDevLocal = process.env.VUE_APP_SITE_DOMAIN;
            if (isDevLocal == 'localhost') {
                document.cookie = `user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            } else {
                document.cookie = "user_id=; path=/; domain=.adviral.link; expires=Thu, 01 Jan 1970 00:00:00 GMT";

            }
            // document.cookie = `user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            // document.cookie = "user_id=; path=/; domain=.adviral.link; expires=Thu, 01 Jan 1970 00:00:00 GMT";

            setTimeout(() => {
                window.location.reload();
            }, 150);
            // document.cookie = ``;
            // this.$store.commit("setParticipant", null);
        },
        switchOfferDetails(id, done, type) {
            if (done) return;
            if (type === "action" && !this.firstOfferDone) {
                this.controlShowOfferNotice(true)
                return;
            }
            this.linkCopied = false;
            // console.log("----id", id, this.showOfferDetails);
            if (id == this.showOfferDetails) {
                this.showOfferDetails = null;
            } else {
                this.showOfferDetails = id;
                this.scrollToSelected()
            }
        },
        scrollToSelected() {
            setTimeout(() => {
                if (this.showOfferDetails) {
                    const element = document.getElementById(this.showOfferDetails)
                    // Use scrollIntoView to scroll the element into view
                    element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
                        
            },   1)
        },
        visitOffer(link) {
            window.open(link, "_blank");
        },
        async verifyAction(action, username) {
            const payload = {
                action: action._id,
                user: this.participant?.user_id,
                contest: this.contest._id,
            };
            if (
                [
                    "twitter-comment",
                    "twitter-follow",
                    "twitter-share",
                    "twitter-retweet",
                    "instagram-comment",
                    "instagram-follow",
                    "instagram-share",
                    "instagram-like",
                    "instagram-view",
                    "tiktok-comment",
                    "tiktok-follow",
                    "tiktok-share",
                    "tiktok-like",
                    "youtube-comment",
                    "youtube-subscribe",
                    "youtube-watch",
                    "youtube-like",
                    "discord-join",
                    "twitch-follow",
                    "twitch-subscribe",
                    "telegram-channel",
                    "telegram-group",
                    'facebook-like-page',
                    'facebook-visit',
                    'facebook-view',
                    'twitter-view'
                ].includes(action.key) &&
                username
            ) {
                payload.username = username;
            }

            await this.$store.dispatch("verifyAction", payload);
            await this.refreshContest(this.sortOption.sort, this.sortOption.sortBy);
            this.showOfferDetails = null;
        },
        async openAction(action, username) {
            let link = `${config.API_URL}/redirect?user_id=${this.participant?.user_id}&type=action&action=${action._id}`;
            if (action.key == "facebook-visit") {
                window.open(link, "_blank");
            }
            if (action.key == "facebook-entry") {
                if (this.participant.facebook) {
                    try {
                        await fetch(link);
                    } catch (error) {
                        console.error("error", error);
                    }
                    return;
                }
                this.linkToVisitAfterFacebookLogin = link;
                this.$nextTick(() => {
                    this.$refs.facebookSvg.dispatchEvent(new Event("click"));
                });
                return;
            }
            if (["twitter-view", "facebook-view", "facebook-like-page"].includes(action.key)) {
                if (action.key === "facebook-like-page" && !this.participant.facebook?.access_token) {
                    this.$nextTick(() => {
                        this.$refs.facebookSvg.dispatchEvent(new Event("click"));
                    });
                    return;
                }

                try {
                    await fetch(link);
                } catch (error) {
                    console.error("error", error);
                }
                await this.refreshContest(this.sortOption.sort, this.sortOption.sortBy);
                this.showOfferDetails = null;
            }
        },
        openUsernamePopup(item) {
            this.selectedActionPopup = item;
        },
        copyLink(link) {
            copyToClipboard(link);
            this.linkCopied = true;
        },
        async handleScroll(e) {
            const { scrollTop, offsetHeight, scrollHeight } = e.target;
            if (
                scrollTop + offsetHeight >= scrollHeight - 5 &&
                !this.loadingSort &&
                !this.loadingMore &&
                this.tab === "tasks" &&
                !this.isDemo
            ) {
                // console.log('----loadMore', this.loadingMore);
                this.loadingMore = true;
                // await this.refreshContest(null,  this.sortOption.sort, this.sortOption.sortBy);
                await this.refreshOffers("scroll", this.sortOption.sort, this.sortOption.sortBy)
                // console.log('---after refresh offers');
                // this.scrollToSelected()
                this.loadingMore = false;
            }
        },
        extractTweetId(value) {
            return getTweetId(value);
        },
        formatInstructions(instructions) {
            return decodeHtmlEntities(instructions)
        },
        displayName(name) {
            return decodeHtmlEntities(name)
        },
        scrollToMiddle() {
            // Use a Vue reference to get the element
            const element = this.$refs.content;

            // Use scrollIntoView to scroll the element into view
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        },
        controlShowOfferNotice(status) {
            this.showOfferNotice = status
            if (status === false) {
                this.setTab("tasks")
                setTimeout(() => {
                    const element = document.getElementById("tasks-tab")
                        // console.log('--elementId', element);
                        // Use scrollIntoView to scroll the element into view
                        element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }, 0)
            }
        } 
    },
    mounted() {
        // document.getElementById("offers-container")?.addEventListener("scroll", this.handleScroll);
        if (this.participant?.entries >= 1 && !this.participant?.email) {
            this.showLoginModal = true;
        }
        // contest(val, valold) {
        //     console.log('--watch-valold', valold);
        //     if (valold === null) {

        if (this.isDemo) {
            const now = moment();
                const targetDate = moment(this.contest.date.end);
                this.counter = targetDate.diff(now);
                const stopCountdown = setInterval(() => {
                    this.counter -= 1000;
                    if (!this.counter) clearInterval(stopCountdown);
                }, 1000);
            // }

            this.table1.data = [...this.contest?.history];
        }

        // },
    },
    created() {},
    unmounted() {
        // document.getElementById("offers-container")?.removeEventListener("scroll", this.handleScroll);
    }, 
    directives: {
        clickOutside: vClickOutside.directive,
    },
    watch: {
        contest(val, valold) {
            // console.log('--watch-valold', valold);
            if (valold === null) {
                const now = moment();
                const targetDate = moment(this.contest.date.end);
                this.counter = targetDate.diff(now);
                const stopCountdown = setInterval(() => {
                    this.counter -= 1000;
                    if (!this.counter) clearInterval(stopCountdown);
                }, 1000);
            }

            this.table1.data = [...val?.history];
            // console.log('---this', this.table1.data);
            const offerConfirmedConversionIndex = this.table1.data.findIndex(x => x.offer && x.status === "confirmed");
            // console.log('----offerConfirmedConversionIndex', offerConfirmedConversionIndex);
            if (offerConfirmedConversionIndex > -1) this.firstOfferDone = true
        },
    },
};
</script>

<style scoped>

@keyframes bounceAndGrow {
    0%, 100% {
        transform: translateY(0) scale(1);
    }
    50% {
        transform: translateY(-20px) scale(1.1);
    }
}

.loading-logo {
    animation: bounceAndGrow 1.2s infinite;
}
    .youtube-icon {
        color: red; font-size: 55px;
    }
    .discord-icon {
        font-size: 50px;
    }
    .facebook-icon {
        color: #1977F2; 
        font-size: 50px;
    }

    @media(max-width: 500px) {
        .youtube-icon {
            font-size: 45px;
        }
        .discord-icon {
            font-size: 40px;
        }
        .facebook-icon {
            font-size: 45px;
        }

    }
</style>