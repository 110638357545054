<template>
    <div v-if="item.key.includes('twitch-')">
        <div>
            <a
                :href="item.key === 'twitch-follow' ? `https://twitch.tv/${item.inputs[0]?.value}` : item.inputs[0]?.value"
                target="_blank"
                class="twitch-button"
            >
                <i class="fab fa-twitch twitch-icon" style=""></i>
                {{ content[item.key]?.["button-title"] }}
            </a>
        </div>

        <div class="mt-4" style="cursor: pointer" @click="onConfirm(item)">
            <span style="text-decoration: underline">{{ content[item.key]?.text }}</span>
        </div>
    </div>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Modal from "@/components/Modal";

const content = {
    'twitch-follow': {
        'button-title': 'Follow',
        'text': 'Already Followed?'
    },
    'twitch-subscribe': {
        'button-title': 'Subscribe',
        'text': 'Already Subscribed?'
    },
}

export default {
    name: "twitch-social-card",
    components: {
        BaseButton,
        ClipLoader,
        Modal,
        // VPopover,
    },
    data() {
        return {
            selectAll: false,
            selected: {},
            content: content
        };
    },
    props: {
        item: Object,
        onConfirm: Function
    },
    updated() {},
    mounted() {},

};
</script>
<style scoped>
.twitch-button {
    background-color: #9146FF;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

</style>
