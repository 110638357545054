<template>
    <div>
        <table class="table tablesorter" :class="tableClass">
            <thead :class="theadClasses">
                <tr>
                    <slot name="columns">
                        <th v-for="column in columns" :key="column">
                            <input
                                v-model="selectAll"
                                style="width: 15px"
                                v-if="column == 'select'"
                                class="form-control"
                                type="checkbox"
                            />
                            <span v-else>{{ column }}</span>
                        </th>
                    </slot>
                </tr>
            </thead>
            <tbody :class="tbodyClasses">
                <tr v-for="(item, index) in showedData" :key="index">
                    <slot :row="item">
                        <td v-for="(column, index) in columns" :key="index" v-if="column == 'select'">
                            <input v-model="selected[item.id]" type="checkbox" />
                        </td>

                        <td
                            v-for="(column, index) in columns"
                            :key="index"
                            v-if="hasValue(item, column) && !['control', 'select'].includes(column)"
                        >
                            <!-- <span v-if="column === 'Type'">{{ item.action?._id ? "Social action" : "Offer" }}</span> -->
                            <span v-if="column === 'id'">{{ item.invoice_id }}</span>
                            <span v-else-if="column === 'Amount'">{{ parseFloat(item.amount ).toFixed(2) }} $</span>
                            <span v-else-if="column === 'Method'">{{ item.payment?.title }}</span>
                            <span v-else-if="column === 'Created at'">{{ formatDate(item.createdAt) }}</span>
                            <span v-else-if="column === 'Covering'">{{ getCovering(item.month) }}</span>
                            <span v-else>
                                {{ itemValue(item, column) }}
                            </span>
                        </td>

                        <td v-for="(column, index) in columns" :key="index" v-if="column == 'control'">
                            <!-- <base-button
                                v-if="!item.delivered && item.status === 'confirmed'"
                                @click="sendCallback(item._id)"
                                size="sm"
                                class="mr-2"
                                :type="'primary'"
                                fill
                            >
                                <clip-loader
                                    v-if="loadings?.['callback'] == item.id"
                                    color="#fff"
                                    size="15px"
                                    :loading="true"
                                ></clip-loader>

                                <span style="margin-left: 0px !important" v-else>Send Callback</span>
                            </base-button> -->
                        </td>
                    </slot>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { calculateDataToShowRanges } from "../services/utils";
import BaseButton from "./BaseButton.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import moment from "moment";

export default {
    name: "offer-table",
    components: {
        BaseButton,
        ClipLoader,
    },
    data() {
        return {
            selectAll: false,
            selected: {},
        };
    },
    props: {
        columns: {
            type: Array,
            default: () => [],
            description: "Table columns",
        },
        data: {
            type: Array,
            default: () => [],
            description: "Table data",
        },
        type: {
            type: String, // striped | hover
            default: "",
            description: "Whether table is striped or hover type",
        },
        theadClasses: {
            type: String,
            default: "",
            description: "<thead> css classes",
        },
        tbodyClasses: {
            type: String,
            default: "",
            description: "<tbody> css classes",
        },
        sendCallback: {
            type: Function,
        },
        perPage: { type: Number },
        currentPage: { type: Number },
        onSelect: {
            type: Function,
        },
        selectedItems: {
            type: Array,
        },
        loadings: Object,
        updateConversion: Function,
    },
    updated() {},
    mounted() {},
    computed: {
        tableClass() {
            return this.type && `table-${this.type}`;
        },
        showedData() {
            const data = calculateDataToShowRanges(this.currentPage, this.perPage, this.data);
            return data;
        },
        totalPages() {
            return this.data.length / this.perPage;
        },
    },
    methods: {
        hasValue(item, column) {
            return item[column.toLowerCase()] !== "undefined";
        },
        itemValue(item, column) {
            return item[column.toLowerCase()];
        },
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        getCovering(month) {
            const start = moment(month, "MMMM").startOf('month').format("YYYY-MM-DD");
            const end = moment(month, "MMMM").endOf('month').format("YYYY-MM-DD");
            return `${start} - ${end}`
        }
    },
    watch: {
        selectAll(value) {
            this.onSelect(
                this.showedData.map((x) => x.id),
                value,
            );
        },
        selectedItems(value) {
            const obj = {};
            for (let i = 0; i < value.length; i++) {
                obj[value[i]] = true;
            }
            if (JSON.stringify(obj) !== JSON.stringify(this.selected)) {
                this.selected = { ...obj };
            }
        },
        selected(value) {
            const ids = Object.keys(value);
            this.onSelect(ids, value);
        },
    },
};
</script>
<style></style>
