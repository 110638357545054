<template>
  <div>
    <notifications></notifications>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
  export default {
    methods: {
      disableRTL() {
        if (!this.$rtl.isRTL) {
          this.$rtl.disableRTL();
        }
      },
      toggleNavOpen() {
        let root = document.getElementsByTagName('html')[0];
        root.classList.toggle('nav-open');
      },
      getUser: async function () {
            this.loading = true;
            const result = await this.$store.dispatch("getUser");
            // console.log('----getUser', result);
            this.model = result.data;
            this.loading = false;
            // console.log('---user profile', this.user, this.model, result);
        },
    async loadRecaptcha() {
      await this.$recaptchaLoaded()
      const recaptcha = this.$recaptchaInstance;
      // console.log('--recaptcha', recaptcha);
      recaptcha.hideBadge();

    }
    },
    created() {
      const theme = localStorage.getItem("theme");
      if (theme == "black") {
        import("@/assets/scss/black-dashboard.scss")
      } else {
        import("@/assets/scss/white-dashboard.scss")
      }
    },
    mounted() {
      if (process.env.VUE_APP_IS_TOOL === 'false' && this.$store.getters.isAuthenticated) {
        this.getUser()
      }
      this.$watch('$route', this.disableRTL, { immediate: true });
      this.$watch('$sidebar.showSidebar', this.toggleNavOpen)
      // this.$store.dispatch("getAlerts");
      this.loadRecaptcha()
    },

  };
</script>

<style lang="scss"></style>
