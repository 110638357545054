import VueRouter from "vue-router";
import routes from "./routes";
import Store from "../store";

// configure router
const router = new VueRouter({
    mode: "history",
    routes,
    linkExactActiveClass: "active",
    scrollBehavior: (to) => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    },
});

// console.log('----!process.env.VUE_APP_IS_TOOL', !process.env.VUE_APP_IS_TOOL)
if (process.env.VUE_APP_IS_TOOL !== "true") {
    router.beforeEach((to, from, next) => {
        const isAuthenticated = Store.getters.isAuthenticated == true;
        if (to.name == "login" || to.name == "register" || to.name == "reset-password" || to.name == "PublicContest") {
            if (isAuthenticated) {
                next('/')
                return;
            }
            next();
        } else if (to.name === "violators" && !Store.getters.user?.is_admin) { 
            next("/")
        }else {
            if (isAuthenticated) {
                next();
            } else {
                next("login");
            }
        }
    });
}

export default router;
