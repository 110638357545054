import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
// import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const Login = () => import(/* webpackChunkName: "dashboard" */ "@/pages/Login.vue");
const Register = () => import(/* webpackChunkName: "dashboard" */ "@/pages/Register.vue");
const ResetPassword = () => import(/* webpackChunkName: "dashboard" */ "@/pages/ResetPassword.vue");
const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Contest = () => import(/* webpackChunkName: "common" */ "@/pages/Contest.vue");
const PublicContest = () => import(/* webpackChunkName: "common" */ "@/pages/PublicContest.vue");
const Notifications = () => import(/* webpackChunkName: "common" */ "@/pages/Notifications.vue");
const Icons = () => import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const Rewards = () => import(/* webpackChunkName: "common" */ "@/pages/Rewards.vue");
const Orders = () => import(/* webpackChunkName: "common" */ "@/pages/Orders.vue");
const Offers = () => import(/* webpackChunkName: "common" */ "@/pages/Offers.vue");
const Contests = () => import(/* webpackChunkName: "common" */ "@/pages/Contests.vue");
const Conversions = () => import(/* webpackChunkName: "common" */ "@/pages/Conversions.vue");
const Users = () => import(/* webpackChunkName: "common" */ "@/pages/Users.vue");
const User = () => import(/* webpackChunkName: "common" */ "@/pages/User.vue");
const Stats = () => import(/* webpackChunkName: "common" */ "@/pages/Stats.vue");
const Referral = () => import(/* webpackChunkName: "common" */ "@/pages/Referral.vue");
const Help = () => import(/* webpackChunkName: "common" */ "@/pages/Help.vue");
const Theme = () => import(/* webpackChunkName: "common" */ "@/pages/Theme.vue");
const Payment = () => import(/* webpackChunkName: "common" */ "@/pages/Payment.vue");
const Violators = () => import(/* webpackChunkName: "common" */ "@/pages/Violators.vue");
const NotFound = () => import(/* webpackChunkName: "common" */ "@/pages/NotFoundPage.vue");

const toolRoutes = [
    {
        path: "/404",
        name: "not-found",
        component: NotFound,
    },
    {
        path: "/contest/:id/public/",
        name: "PublicContest",
        component: PublicContest,
    },
    {
        path: "/:id",
        name: "PublicContest",
        component: PublicContest,
    },
    { path: "*", component: NotFound },
]

const membersRoutes = [
    {
        path: "/",
        component: DashboardLayout,
        redirect: "/dashboard",
        children: [
            {
                path: "dashboard",
                name: "dashboard",
                component: Dashboard,
            },
            {
                path: "profile",
                name: "profile",
                component: Profile,
            },
            {
                path: "contest/:id",
                name: "contest",
                component: Contest,
            },
            {
                path: "contest/theme/:id",
                name: "createTheme",
                component: Contest,
            },
            // {
            //     path: "notifications",
            //     name: "notifications",
            //     component: Notifications,
            // },
            // {
            //     path: "icons",
            //     name: "icons",
            //     component: Icons,
            // },
            // {
            //     path: "maps",
            //     name: "maps",
            //     component: Maps,
            // },
            // {
            //     path: "typography",
            //     name: "typography",
            //     component: Typography,
            // },
            {
                path: "offers",
                name: "offers",
                component: Offers,
            },
            {
                path: "rewards",
                name: "rewards",
                component: Rewards,
            },
            // {
            //     path: "orders",
            //     name: "orders",
            //     component: Orders,
            // },
            {
                path: "contests",
                name: "contests",
                component: Contests,
            },
            // {
            //     path: "conversions",
            //     name: "conversions",
            //     component: Conversions,
            // },
            // {
            //     path: "users",
            //     name: "users",
            //     component: Users,
            // },
            {
                path: "users/:id",
                name: "user",
                component: User,
            },
            {
                path: "stats",
                name: "stats",
                component: Stats,
            },
            {
                path: "referrals",
                name: "referrals",
                component: Referral,
            },
            {
                path: "/help",
                name: "help",
                component: Help,
            },
            {
                path: "/themes",
                name: "themes",
                component: Theme,
            },
            {
                path: "/payment",
                name: "payment",
                component: Payment,
            },
            {
                path: "/violators",
                name: "violators",
                component: Violators,
            },
        ],
    },
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/register",
        name: "register",
        component: Register,
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: ResetPassword,
    },
    {
        path: "/404",
        name: "not-found",
        component: NotFound,
    },
    { path: "*", component: NotFound },
];

const routes = process.env.VUE_APP_IS_TOOL === "true" ? toolRoutes : membersRoutes

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
