<template>
    <div class="row" style="background-color: white">

        <modal :width="600" :show="show" id="searchModal" :centered="false" :show-close="true" :enable-theme="false">
            <div class="row justify-content-between align-items-center">
                <h4 class="mb-1" style="font-weight: 700;">History</h4>
                <base-button
                  @click="openDiscord()"
                  :backgroundColor="'#5865F2'"
                  :type="'primary'"
                  class="mb-2"
                  size="sm"
                  
              >
                  <span style="margin-left: 0px !important; color: white;" ><i style="font-size: 16px" class="fab fa-discord mr-2"></i>Help</span>
              </base-button>
            </div>

            <table class="table tablesorter" :class="tableClass">
                <thead
                    :class="theadClasses"
                    style="
                        border-bottom-width: 1px;
                        border-bottom-color: black;
                        border-bottom: solid;
                        border-bottom-width: 1.2px;
                        border-color: #28293d !important;
                    "
                >
                    <tr>
                        <slot name="columns">
                            <th v-for="column in columns" :key="column">
                                <input
                                    v-model="selectAll"
                                    style="width: 15px"
                                    v-if="column == 'select'"
                                    class="form-control"
                                    type="checkbox"
                                />
                                <span v-else>{{ column }}</span>
                            </th>
                        </slot>
                    </tr>
                </thead>
                <tbody :class="tbodyClasses">
                    <tr v-for="(item, index) in showedData" :key="index" style="border-bottom: 0.07rem solid #28293d">
                        <slot :row="item">
                            <td v-for="(column, index) in columns" :key="index" v-if="column == 'select'">
                                <input v-model="selected[item.id]" type="checkbox" />
                            </td>

                            <td
                                v-for="(column, index) in columns"
                                :key="index"
                                v-if="hasValue(item, column) && !['control', 'select'].includes(column)"
                                style="color: #28293d !important"
                            >
                                <span v-if="column === 'Title'">{{ item.action?.title || item.offer?.name }}</span>
                                <span v-else-if="column === 'Type'">{{ item.action ? "social share" : "task" }}</span>
                                <span v-else-if="column === 'Amount'">{{ renderAmount(item) }}</span>
                                <div style="display: flex;" v-else-if="column === 'Status'">
                                    <span class="mr-2">{{ item.status}} </span>
                                    <help-tooltip v-if="item.status !== 'confirmed'" :show="true"></help-tooltip>
                                </div>
                                <span v-else>
                                    {{ itemValue(item, column) }}
                                </span>
                            </td>
                        </slot>
                    </tr>
                </tbody>
            </table>
            <div class="row justify-content-center">
                <base-button style="background: #333333;" size="sm" @click="close">Close</base-button>
            </div>
        </modal>
    </div>
</template>
<script>
import { calculateDataToShowRanges } from "../../services/utils";
import BaseButton from "@/components/BaseButton.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Modal from "@/components/Modal";
import "./historyTable.css";
// import Vue from 'vue'
import HelpTooltip from './HelpTooltip.vue'

// Vue.directive('tooltip', VTooltip)
// Vue.directive('close-popover', VClosePopover)
// Vue.component('v-popover', VPopover)
// Vue.use(Tooltip);

export default {
    name: "history-table",
    components: {
        BaseButton,
        ClipLoader,
        Modal,
        // VPopover,
        HelpTooltip
    },
    data() {
        return {
            selectAll: false,
            selected: {},
            model: 'Hello world is title'
        };
    },
    props: {
        show: Boolean,
        columns: {
            type: Array,
            default: () => [],
            description: "Table columns",
        },
        data: {
            type: Array,
            default: () => [],
            description: "Table data",
        },
        type: {
            type: String, // striped | hover
            default: "",
            description: "Whether table is striped or hover type",
        },
        theadClasses: {
            type: String,
            default: "",
            description: "<thead> css classes",
        },
        tbodyClasses: {
            type: String,
            default: "",
            description: "<tbody> css classes",
        },
        sendCallback: {
            type: Function,
        },
        perPage: { type: Number },
        currentPage: { type: Number },
        onSelect: {
            type: Function,
        },
        selectedItems: {
            type: Array,
        },
        loadings: Object,
        updateConversion: Function,
        close: Function,
    },
    updated() {},
    mounted() {},
    computed: {
        tableClass() {
            return this.type && `table-${this.type}`;
        },
        showedData() {
            const data = calculateDataToShowRanges(this.currentPage, this.perPage, this.data);
            return data;
        },
        totalPages() {
            return this.data.length / this.perPage;
        },
    },
    methods: {
        hasValue(item, column) {
            return item[column.toLowerCase()] !== "undefined";
        },
        itemValue(item, column) {
            return item[column.toLowerCase()];
        },
        openDiscord() {
            window.open('https://discord.gg/x66UaQCmVJ', "_blank")
        },
        renderAmount(item) {
            if (item.amount && item.action) {
                return item.amount
            }else if(item.amount) {
                return item.amount * 100
            } else {
                return "-"
            }
        }
    },
    watch: {
        selectAll(value) {
            this.onSelect(
                this.showedData.map((x) => x.id),
                value,
            );
        },
        selectedItems(value) {
            const obj = {};
            for (let i = 0; i < value.length; i++) {
                obj[value[i]] = true;
            }
            if (JSON.stringify(obj) !== JSON.stringify(this.selected)) {
                this.selected = { ...obj };
            }
        },
        selected(value) {
            const ids = Object.keys(value);
            this.onSelect(ids, value);
        },

    },
};
</script>
<style></style>
