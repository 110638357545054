<template>
  <div class="form-group"
       :class="{
          'input-group': hasIcon,
          'input-group-focus': focused
       }">
    <slot name="label">
      <label v-if="label" class="control-label" :class="{'bold': source == 'offer'}">
        {{label}}
      </label>
    </slot>
    <slot name="addonLeft">
      <span v-if="addonLeftIcon" class="input-group-prepend">
        <div class="input-group-text">
          <i :class="addonLeftIcon"></i>
        </div>
      </span>
    </slot>
    <slot>
      <select
        class="form-control"
        v-model="model"
        :class="{
          'black-select': theme == `black`
        }"
      >
        <option v-for="option in options" :value="option.value">{{option.title}}</option>
      </select>
    </slot>
    <slot name="addonRight">
      <span v-if="addonRightIcon" class="input-group-append">
        <div class="input-group-text">
          <i :class="addonRightIcon"></i>
        </div>
      </span>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>

  export default {
    inheritAttrs: false,
    name: "base-select",
    props: {
      label: {
        type: String,
        description: "Input label"
      },
      default: {
        type: [String, Number],
        description: "Input label"
      },
      value: {
        type: [String, Number],
        description: "Input value"
      },
      addonRightIcon: {
        type: String,
        description: "Input icon on the right"
      },
      addonLeftIcon: {
        type: String,
        description: "Input icon on the left"
      },
      options: {
        type: Array,
        description: "Input icon on the left"
      },
      onChange: {
        type: Function,
        description: "Input icon on the left"
      },
      source: String
    },
    data() {
      return {
        focused: false,
        model: "",
        theme: localStorage.getItem("theme")
      }
    },
    created() {
      // console.log('---select creater per page', this.default, this.value, this.theme);
      this.model = this.default || "all"
    },
    updated() {
      // console.log('---this.value', this.label, this.default, this.default, this.model);
      this.model = this.default;
    },
    computed: {
      hasIcon() {
        const { addonRight, addonLeft } = this.$slots;
        return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
      },
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput,
          blur: this.onBlur,
          focus: this.onFocus
        }
      }
    },
    methods: {
      onInput(evt) {
        this.$emit('input', evt.target.value)
      },
      onFocus() {
        this.focused = true;
      },
      onBlur() {
        this.focused = false;
      }
    },
    watch: {
      model(val, oldVal) {
        if (val !== oldVal) {
          this.onChange(val)
        }
      },
      default(val) {
        // console.log('---watchdefault', this.label, val);
        this.model = val;
      }
    }
  }
</script>
<style>

</style>
