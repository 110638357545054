<template>
    <div class="row container my-3 px-0" :style="{color: mainColor}">
      <div class="p-2 column ">
        <i class="fas fa-users"></i>
        <span>New users only</span>
      </div>
      <div class="separator" :style="{background: mainColor}"></div>
      <div class="p-2 column">
        <i class="fas fa-globe-americas"></i>
        <span>No VPN</span>
      </div>
      <div class="separator" :style="{background: mainColor}"></div>
      <div class="p-2 column">
        <i class="fas fa-laptop"></i>
        <span>No emulators</span>
      </div>
      <div class="separator" :style="{background: mainColor}"></div>
      <div class="p-2 column">
        <i class="fas fa-comment-dollar"></i>
        <span>Free offer</span>
      </div>
      <div class="separator" :style="{background: mainColor}"></div>
      <div class="p-2 column">
        <i class="fas fa-hand-paper"></i>
        <span>No ad blockers</span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      mainColor: String
    },
    data() {
      return {
        showTooltip: false,
      };
    },
  };
</script>
  
<style scoped>
  .container {
    flex-wrap: nowrap;
  }

  .column i{
    font-size: 19px;
    margin-bottom: 5px;
  }

  .column span {
    font-size: 13px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .column {
    position: relative;
  }

  .separator {
    width: 1.5px;
    height: 70px;
  }

</style>
  