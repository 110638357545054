<template>
    <div>
        <table class="table tablesorter" :class="tableClass">
            <thead :class="theadClasses">
                <tr>
                    <slot name="columns">
                        <th v-for="column in columns" :key="column.key" @click="setSortBy(column.key)" style="cursor: pointer">
                            <input
                                v-model="selectAll"
                                style="width: 15px"
                                v-if="column.key == 'select'"
                                class="form-control"
                                type="checkbox"
                            />
                            <div v-else>
                                <span>{{ column.title }}</span>
                                <i
                                    v-if="sortBy?.toLowerCase() == column?.key?.toLowerCase()"
                                    class="tim-icons ml-2"
                                    :class="{
                                        'icon-minimal-down': sort == -1,
                                        'icon-minimal-up': sort == 1,
                                    }"
                                ></i>
                            </div>
                        </th>
                    </slot>
                </tr>
            </thead>
            <tbody :class="tbodyClasses">
                <tr v-if="!loading" v-for="(item, index) in showedData" :key="index">
                    <slot :row="item">
                        <td v-for="(column, index) in columns" :key="index" v-if="column.key == 'select'">
                            <input v-model="selected[item.id]" type="checkbox" />
                        </td>
                        <td
                            v-for="(column, index) in columns"
                            :key="index"
                            v-if="hasValue(item, column.key) && !['control', 'select', 'icon'].includes(column.key)"
                        >
                            <!-- <span v-if="column === 'name'">{{ item?.name || "Undefined" }}</span>
                            <span v-else-if="column === 'email'">{{ item?.email || "Undefined" }}</span>
                            <span v-else-if="column === 'contest'">{{ item?.contest.title }}</span>
                            <span v-else-if="column === 'Social Shares'">{{ item?.total_social }}</span>
                            <span v-else-if="column === 'Payout'">{{ item?.total_payout }}</span>
                            <span v-else-if="column === 'participant id'">{{ item?.user_id }}</span> -->
                            <span>
                                {{ itemValue(item, column.key) }}
                            </span>
                        </td>
                        <td v-for="(column, index) in columns" :key="index" v-if="column.key == 'control'">
                            <base-button
                                class="mr-2 mb-1"
                                size="sm"
                                @click="pickWinner(item.contest._id, item._id)"
                                type="primary"
                                :disabled="item.contest.winners?.includes(item._id)"
                                fill
                            >
                                <clip-loader
                                    v-if="loadingItem?.['winner'] == item._id"
                                    color="#fff"
                                    size="15px"
                                    :loading="true"
                                ></clip-loader>

                                <span style="margin-left: 0px !important" v-else>Pick as winner</span>
                            </base-button>
                            <base-button
                                class="mr-2 mb-1"
                                size="sm"
                                @click="showDetails(item._id)"
                                type="secondary"
                                fill
                            >
                                <clip-loader
                                    v-if="loadingItem?.['winner'] == item._id"
                                    color="#fff"
                                    size="15px"
                                    :loading="true"
                                ></clip-loader>

                                <span style="margin-left: 0px !important" v-else>More details</span>
                            </base-button>
                        </td>
                    </slot>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import BaseButton from "./BaseButton.vue";
import { Pagination } from "@/components";
import moment from "moment";
import { calculateDataToShowRanges } from "../services/utils";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    name: "offer-table",
    components: {
        BaseButton,
        Pagination,
        ClipLoader,
    },
    data() {
        return {
            selectAll: false,
            selected: {},
            sortBy: "_id",
            sort: 1,
        };
    },
    props: {
        columns: {
            type: Array,
            default: () => [],
            description: "Table columns",
        },
        data: {
            type: Array,
            default: () => [],
            description: "Table data",
        },
        type: {
            type: String, // striped | hover
            default: "",
            description: "Whether table is striped or hover type",
        },
        theadClasses: {
            type: String,
            default: "",
            description: "<thead> css classes",
        },
        tbodyClasses: {
            type: String,
            default: "",
            description: "<tbody> css classes",
        },
        edit: {
            type: Function,
        },
        perPage: { type: Number },
        currentPage: { type: Number },
        onSelect: {
            type: Function,
        },
        selectedItems: {
            type: Array,
        },
        loadingItem: Object,
        loading: Boolean,
        pickWinner: Function,
        showDetails: Function,
    },
    computed: {
        tableClass() {
            return this.type && `table-${this.type}`;
        },
        showedData() {
            let data = this.data;
            const { sort, sortBy } = this;

            if (sortBy) {
                data = data.sort((a, b) => {
                    // console.log("---inside", sortBy, sort);
                    // console.log(isNaN(a?.[sortBy]), a[sortBy].localeCompare);
                    const aValue = this.itemValue(a, sortBy)
                    const bValue = this.itemValue(b, sortBy)
                    if (sort == 1) {
                        return isNaN(aValue) && aValue?.localeCompare && bValue?.localeCompare
                            ? aValue?.localeCompare(bValue)
                            : aValue - bValue;
                    } else {
                        return isNaN(aValue) && aValue?.localeCompare && bValue?.localeCompare
                            ? bValue?.localeCompare(aValue)
                            : bValue - aValue;
                    }
                });
            }

            data = calculateDataToShowRanges(this.currentPage, this.perPage, data);
            return data;
        },
        totalPages() {
            return this.data.length / this.perPage;
        },
    },
    methods: {
        hasValue(item, column) {
            return item[column.toLowerCase()] !== "undefined";
        },
        itemValue(item, column) {
            if (column === "contest.winner") {
                return item.contest.winners?.includes(item._id) ? "Yes" : "-"
            }

            if (column?.toLowerCase() === "createdat") {
                return moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
            }

            const parts = column.split('.');
            if (parts.length > 1) {
                let obj = item;
                for (let i = 0; i < parts.length; i++) {
                    const part = parts[i];
                    obj = obj[part] || '-'
                }

                return obj 
            }

            const value = item[column.toLowerCase()] 
            return ![undefined, null].includes(value) ? value : '-';
        },
        setSortBy(column) {
            if (column === "control") return;
            this.sortBy = column?.toLowerCase();
            
            this.sort = !this.sort || this.sort == -1 ? 1 : -1;
        },
    },
    watch: {
        currentPage() {},
        selectAll(value) {
            this.onSelect(
                this.showedData.map((x) => x.id),
                value,
            );
        },
        selectedItems(value) {
            const obj = {};
            for (let i = 0; i < value.length; i++) {
                obj[value[i]] = true;
            }
            if (JSON.stringify(obj) !== JSON.stringify(this.selected)) {
                this.selected = { ...obj };
            }
        },
        selected(value) {
            const ids = Object.keys(value);
            this.onSelect(ids, value);
        },
    },
};
</script>
<style></style>
