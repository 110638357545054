import Vuex from "vuex";
import Vue from "vue";

import vueAuth from "./services/auth.js";

import Api from "./services/api.js";

Vue.use(Vuex);

export default new Vuex.Store({
    // You can use it as state property
    state: {
        isAdmin: null,
        isAuthenticated: vueAuth.isAuthenticated(),
        authToken: vueAuth.getToken(),
        authError: null,
        error: null,
        user: null,
        totalOffers: 0,
        offers: [],
        offersDemos: [],
        contests: [],
        contestEntries: [],
        contest: null,
        publicContest: null,
        publicContestOffers: [],
        rewards: [],
        reward: null,
        conversions: [],
        orders: [],
        users: [],
        visitor: null,
        stats: {},
        reports: {},
        referralReports: [],
        referralSummary: [],
        referralLink: "",
        globalStats: {},
        conversionsFeed: [],
        alerts: [],
        errors: {},
        loads: {},
        successMessages: {},
        clicks: [],
        violations: [],
        settings: [],
        participant: {},
        contestParticipants: [],
        help: {
            videos: [],
            articles: [],
        },
        themes: [],
        payments: [],
        month_earning: 0,
        paymentHistory: [],
        violators: [],
    },

    // You can use it as a state getter function (probably the best solution)
    getters: {
        isAuthenticated: (state) => {
            // console.log("-----state", state);
            return state.isAuthenticated;
        },
        isAdmin: (state) => {
            return state.isAdmin;
        },
        authToken: (state) => {
            return state.authToken;
        },
        isMobile: (state) => {
            if (
                navigator.userAgent.match(/Android/i) ||
                navigator.userAgent.match(/webOS/i) ||
                navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/iPod/i) ||
                navigator.userAgent.match(/BlackBerry/i) ||
                navigator.userAgent.match(/Windows Phone/i)
            ) {
                return true;
            } else {
                return false;
            }
        },
        authError: (state) => {
            return state.authError;
        },
        error: (state) => {
            return state.error;
        },
        user: (state) => {
            return state.user;
        },
        participant: (state) => state.participant,
        contestParticipants: (state) => state.contestParticipants,
        totalOffers: (state) => state.totalOffers,
        offers: (state) => state.offers,
        offersDemos: (state) => state.offersDemos,
        contests: (state) => state.contests,
        contestEntries: (state) => state.contestEntries,
        contest: (state) => state.contest,
        publicContest: (state) => {
            const contest = state.publicContest;
            // console.log("---contest", contest);
            if (contest) {
                const actions = contest.actions.map((x) => {
                    const obj = { ...x };
                    if (x.dynamics?.length > 0) {
                        x.dynamics.forEach((dynamic) => {
                            const input = obj.inputs.find((inp) => inp.title === dynamic.by);
                            if (input) {
                                obj.title = obj.title.replace(dynamic.replace, input.value);
                            }
                        });
                    }
                    return obj;
                });

                return { ...contest, actions };
            }
            return contest;
        },
        publicContestOffers: (state) => state.publicContestOffers,
        rewards: (state) => state.rewards,
        reward: (state) => state.reward,
        conversions: (state) => state.conversions,
        conversionsFeed: (state) => state.conversionsFeed,
        orders: (state) => state.orders,
        users: (state) => state.users,
        visitor: (state) => state.visitor,
        stats: (state) => state.stats,
        reports: (state) => state.reports,
        referralReports: (state) => state.referralReports,
        referralSummary: (state) => state.referralSummary,
        referralLink: (state) => state.referralLink,
        globalStats: (state) => state.globalStats,
        alerts: (state) => state.alerts,
        errors: (state) => state.errors,
        loads: (state) => state.loads,
        successMessages: (state) => state.successMessages,
        clicks: (state) => state.clicks,
        violations: (state) => state.violations,
        settings: (state) => state.settings,
        help: (state) => state.help,
        themes: (state) => state.themes,
        payments: (state) => state.payments,
        paymentHistory: (state) => state.paymentHistory,
        month_earning: (state) => state.month_earning,
        violators: (state) => state.violators,
    },

    // Mutation for when you use it as state property
    mutations: {
        setAdmin(state, payload) {
            state.admin = payload;
        },
        isAuthenticated(state, payload) {
            if (payload.isAuthenticated != undefined) {
                state.isAuthenticated = payload.isAuthenticated;
            }
        },
        setAuthError(state, payload) {
            state.authError = payload;
        },
        setError(state, payload) {
            state.setError = payload;
        },
        setUser(state, payload) {
            state.user = payload;
        },
        setParticipant(state, payload) {
            state.participant = payload;
        },
        setContestParticipants(state, payload) {
            state.contestParticipants = payload;
        },
        setTotalOffers(state, payload) {
            state.totalOffers = payload;
        },
        setOffers(state, payload) {
            state.offers = payload;
        },
        setOffersDemos(state, payload) {
            state.offersDemos = payload;
        },
        setContestEntries(state, payload) {
            state.contestEntries = payload;
        },
        setContests(state, payload) {
            state.contests = payload;
        },
        setContest(state, payload) {
            state.contest = payload;
        },
        setPublicContest(state, payload) {
            state.publicContest = payload;
        },
        setPublicContestOffers(state, payload) {
            const { override, offers } = payload;
            // console.log("----override", override);
            if (override) {
                state.publicContestOffers = offers;
                return;
            }
            const nonDuplicatedOffers = [];
            for (let i = 0; i < offers.length; i++) {
                const offer = offers[i];
                const index = state.publicContestOffers.findIndex((x) => x._id == offer._id);
                if (index < 0) {
                    nonDuplicatedOffers.push(offer);
                }
            }
            state.publicContestOffers = state.publicContestOffers.concat(nonDuplicatedOffers);
        },
        clearPublicContestOffers(state, payload) {
            state.publicContestOffers = [];
        },
        setRewards(state, payload) {
            state.rewards = payload;
        },
        setReward(state, payload) {
            state.reward = payload;
        },
        setConversions(state, payload) {
            state.conversions = payload;
        },
        setConversionsFeed(state, payload) {
            state.conversionsFeed = payload;
        },
        setOrders(state, payload) {
            state.orders = payload;
        },
        setUsers(state, payload) {
            state.users = payload;
        },
        setVisitor(state, payload) {
            state.visitor = payload;
        },
        setStats(state, payload) {
            state.stats = payload;
        },
        setReports(state, payload) {
            state.reports = payload;
        },
        setReferralReports(state, payload) {
            state.referralReports = payload;
        },
        setReferralSummary(state, payload) {
            state.referralSummary = payload;
        },
        setReferralLink(state, payload) {
            state.referralLink = payload;
        },
        setGlobalStats(state, payload) {
            state.globalStats = payload;
        },
        setAlerts(state, payload) {
            state.alerts = payload;
        },
        setErrors(state, payload) {
            state.errors = { ...state.errors, ...payload };
        },
        setLoads(state, payload) {
            // console.log("--setLoads", payload);
            state.loads = { ...state.loads, ...payload };
        },
        setSuccessMessages(state, payload) {
            state.successMessages = { ...state.successMessages, ...payload };
        },
        setClicks(state, payload) {
            state.clicks = payload;
        },
        setViolations(state, payload) {
            state.violations = payload;
        },
        setSettings(state, payload) {
            state.settings = payload;
        },
        setHelp(state, payload) {
            state.help = payload;
        },
        setThemes(state, payload) {
            state.themes = payload;
        },
        setPayments(state, payload) {
            state.payments = payload;
        },
        setPaymentHistory(state, payload) {
            state.paymentHistory = payload;
        },
        setMonthEarning(state, payload) {
            state.month_earning = payload;
        },
        setViolators(state, payload) {
            state.violators = payload;
        },
    },

    actions: {
        authenticate(context, payload) {
            //DONE: Create contests
            //DONE: Implement Sockets for realtime users earnings
            var userData = { RefID: localStorage["rbxboost.refID"] } || undefined;
            vueAuth
                .authenticate(payload.provider, userData)
                .then((response) => {
                    if (
                        response != null &&
                        response.status == 200 &&
                        response.data != null &&
                        response.data.success == true
                    ) {
                        // console.log(response);
                        context.commit("isAuthenticated", {
                            isAuthenticated: vueAuth.isAuthenticated(),
                            Points: response.data.Points,
                            Picture: response.data.Picture,
                            Username: response.data.Name,
                            ID: response.data.ID,
                        });
                    } else if (response.data.success == false && response.data.message) {
                        //DONE: Show Error message
                        context.commit("openDialog", {
                            Title: "Error",
                            Message: "There was a problem signing in. " + response.data.message,
                        });
                    }
                })
                .catch((err) => {
                    //DONE: Show Error message
                    context.commit("openDialog", {
                        Title: "Error",
                        Message: "There was a problem while signing in. Please try again.",
                    });
                });
        },
        resetPassword: async (context, payload) => {
            try {
                const result = await Api.resetPassword(payload);
                if (result.status == 200) return true;
            } catch (error) {
                console.error(error);
                context.commit(
                    "setAuthError",
                    error?.response?.data?.message ||
                        "There was a problem resetting your password, please try again later or contacts us.",
                );
            }
            return false;
        },
        verify: async (context, payload) => {
            try {
                const result = await Api.verify(payload);
                if (result.status == 200) return true;
            } catch (error) {
                console.error(error);
                context.commit(
                    "setAuthError",
                    error?.response?.data?.message ||
                        "There was a problem verifying your request, please try again later or contacts us.",
                );
            }
            return false;
        },
        updatePassword: async (context, payload) => {
            try {
                const result = await Api.updatePassword(payload);
                if (result.status == 200) return true;
            } catch (error) {
                // console.error(error.response.data);
                context.commit(
                    "setAuthError",
                    error?.response?.data ||
                        "There was a problem updating your password, please try again later or contacts us.",
                );
            }
            return false;
        },
        updateUser: async (context, payload) => {
            try {
                const result = await Api.updateUser(payload, vueAuth.getToken());
                if (result.status == 200) return { success: true, data: result.data?.data?.user };
            } catch (error) {
                console.error(error);
                context.commit(
                    "setAuthError",
                    error?.response?.data ||
                        "There was a problem updating your password, please try again later or contacts us.",
                );
                context.commit("setErrors", {
                    [`${payload.source == "password" ? "password" : "profile"}`]:
                        error?.response?.data ||
                        "There was a problem updating your info, please try again later or contacts us.",
                });
            }
            return false;
        },
        getUser: async (context, payload) => {
            try {
                const result = await Api.getUser(vueAuth.getToken());
                // console.log("-----getUser", result.data?.data?.user);
                if (result.status == 200) {
                    context.commit("setUser", result.data?.data?.user);
                    context.commit("setReferralLink", result.data?.data?.referralLink);
                }
                if (result.status == 200) return { success: true, data: result.data?.data?.user };
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        countOffers: async (context, payload) => {
            try {
                // context.commit("setTotalOffers", 100);
                // return;
                const result = await Api.countOffers(payload, vueAuth.getToken());
                // console.log("----result", result?.data);
                if (result.status == 200) {
                    context.commit("setTotalOffers", result.data.data?.total);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getOffers: async (context, payload) => {
            try {
                context.commit("setOffers", []);
                const result = await Api.getOffers(payload, vueAuth.getToken());
                // console.log("---result", result?.data);
                if (result.status == 200) {
                    // context.commit("setOffers", []);
                    context.commit("setOffers", result.data?.data?.offers);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getOffersDemos: async (context, payload) => {
            try {
                const result = await Api.getOffersDemos(payload, vueAuth.getToken());
                // console.log("---result", result?.data);
                if (result.status == 200) {
                    context.commit("setOffersDemos", result.data?.data?.offers);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        updateOffer: async (context, payload) => {
            try {
                // console.log('-------payload', payload);
                const result = await Api.updateOffer(payload, vueAuth.getToken());
                // console.log("---result", result?.data);
                if (result.status == 200) {
                    const offers = context.state.offers;
                    for (let i = 0; i < offers.length; i++) {
                        const offer = offers[i];
                        // console.log('----payload.ids?.includes(offer._id)', payload.ids?.includes(offer._id));
                        if (payload.ids?.includes(offer._id)) {
                            if (payload.pin !== undefined) {
                                offer.pinned = payload.pin || false;
                            }
                            if (payload.active !== undefined) {
                                offer.active = payload.active || false;
                            }
                        }

                        // if (payload.active) {
                        //     offer.pinned = payload.pin
                        // }
                    }
                    context.commit("setOffers", offers);
                    return true;
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getPublicContest: async (context, payload, router) => {
            try {
                const result = await Api.getPublicContest(payload, vueAuth.getToken());
                // console.log("---result", result?.data?.data);
                if (result.status == 200) {
                    context.commit("setPublicContest", result.data?.data?.contest);
                    context.commit("setParticipant", result.data?.data?.user);
                    // document.cookie = `user_id=` + result.data?.data?.user?.user_id + ";path=/; sameSite=None; Secure; domain=.adviral.link";
                    // document.cookie = `user_id=` + result.data?.data?.user?.user_id + ";path=/";
                    // localStorage.setItem('participant_id', result.data?.data?.user?.user_id )
                    const isDevLocal = process.env.VUE_APP_SITE_DOMAIN;
                    // console.log("---isDevLocal", isDevLocal);
                    var date = new Date();
                    date.setMonth(date.getMonth() + 2); // Add 1 month to the current date
                    var expires = "; expires=" + date.toUTCString();
                    // console.log("----isDevLocal", isDevLocal);
                    if (isDevLocal == "localhost") {
                        document.cookie = `user_id=` + result.data?.data?.user?.user_id + ";path=/";
                    } else {
                        document.cookie =
                            `user_id=` +
                            result.data?.data?.user?.user_id +
                            ";path=/; sameSite=None; Secure; domain=.adviral.link" +
                            expires;
                    }
                }
            } catch (error) {
                console.error(error);
                // router.push("/404");
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getPublicContestOffers: async (context, payload) => {
            try {
                const result = await Api.getPublicContestOffers(payload, vueAuth.getToken());
                // console.log("---result", result?.data?.data);
                if (result.status == 200) {
                    context.commit("setPublicContestOffers", {
                        offers: result.data?.data?.offers,
                        override: payload.override,
                    });
                    return { success: true, total: result.data?.data?.offers?.length };
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getContest: async (context, payload) => {
            try {
                const result = await Api.getContest(payload, vueAuth.getToken());
                // console.log("---result", result?.data?.data);
                if (result.status == 200) {
                    context.commit("setContest", result.data?.data?.contest);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getContestEntries: async (context, payload) => {
            try {
                // console.log("--get contests entries");
                const result = await Api.getContestEntries(payload, vueAuth.getToken());
                // console.log("---result", result?.data?.data);
                if (result.status == 200) {
                    context.commit("setContestEntries", result.data?.data?.entries);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getContests: async (context, payload) => {
            try {
                // console.log("--get contests action");
                const result = await Api.getContests(payload, vueAuth.getToken());
                // console.log("---result", result?.data?.data);
                if (result.status == 200) {
                    context.commit("setContests", result.data?.data?.contests);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        createContest: async (context, payload) => {
            context.commit("setSuccessMessages", { contest: undefined });
            context.commit("setErrors", { contest: undefined });
            // console.log("---herre", payload);
            try {
                const result = await Api.createContest(payload, vueAuth.getToken());
                // console.log("---result", result?.data, result.status);
                if (result.status == 200) {
                    context.commit("setSuccessMessages", {
                        contest: "Successfully Created",
                    });
                }

                return true;
            } catch (error) {
                console.error(error.response);
                context.commit("setErrors", {
                    contest: error?.response?.data || "Something went wrong, please try again later",
                });
            }

            return false;
        },
        updateContest: async (context, payload) => {
            context.commit("setLoads", { updateContest: true });
            try {
                context.commit("setErrors", { contest: undefined });
                context.commit("setSuccessMessages", { contest: undefined });
                const result = await Api.updateContest(payload, vueAuth.getToken());
                // console.log('---result', result?.data);
                if (result.status == 200) {
                    const contests = context.state.contests;

                    for (let i = 0; i < contests.length; i++) {
                        const contest = contests[i];
                        if (payload.ids?.includes(contest._id)) {
                            if (payload.active !== undefined) {
                                contest.active = payload.active || false;
                            }
                        }
                    }
                    context.commit("setSuccessMessages", {
                        contest: "Successfully updated",
                    });
                    context.commit("setContests", contests);
                }
            } catch (error) {
                // console.error('updateContest ------------', error.response.data.message)
                context.commit("setErrors", {
                    contest: error?.response?.data?.message || "Something went wrong, please try again later",
                });
            }
            context.commit("setLoads", { updateContest: false });
        },
        deleteContest: async (context, payload) => {
            try {
                const result = await Api.deleteContest(payload, vueAuth.getToken());
                // console.log('---result', result?.data);
                if (result.status == 200) {
                    // context.commit("setOffers", result.data?.data?.offers);
                    const contests = context.state.contests;
                    const index = contests.findIndex((x) => x._id == payload);
                    // console.log("---index", { index }, contests.length);
                    if (index > -1) {
                        // console.log("---inside");
                        contests.splice(index, 1);
                    }
                    // console.log("---index after", contests.length);

                    context.commit("setContests", contests);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getConversionsFeed: async (context, payload) => {
            try {
                const result = await Api.getConversions(payload, vueAuth.getToken());
                if (result.status == 200) {
                    context.commit("setConversionsFeed", result.data?.data?.conversions);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getConversions: async (context, payload) => {
            try {
                const result = await Api.getConversions(payload, vueAuth.getToken());
                if (result.status == 200) {
                    context.commit("setConversions", result.data?.data?.conversions);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        updateConversion: async (context, payload) => {
            try {
                const result = await Api.updateConversion(payload, vueAuth.getToken());
                if (result.status == 200) {
                    // context.commit("setConversions", result.data?.data?.offers);
                    if (result.status == 200) {
                        const conversions = context.state.conversions;

                        for (let i = 0; i < conversions.length; i++) {
                            const conversion = conversions[i];
                            if (payload.ids.includes(conversion._id.toString())) {
                                conversion.status = payload.status;
                            }
                        }
                        // context.commit("setSuccessMessages",{
                        //     "conversion": "Successfully updated"
                        // })
                        context.commit("setConversions", conversions);
                    }
                }
                return { success: true, message: "Conversion successfully updated" };
            } catch (error) {
                console.error(error);
                const message = error?.response?.data?.message || "Something went wrong, please try again later";
                // console.log("-----------error message", message);
                // context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
                return { success: false, message: message };
            }
            return false;
        },
        sendCallback: async (context, payload) => {
            try {
                const result = await Api.sendCallback(payload, vueAuth.getToken());
                if (result.status == 200) {
                    // context.commit("setConversions", result.data?.data?.offers);
                    if (result.status == 200) {
                        const conversions = context.state.conversions;

                        for (let i = 0; i < conversions.length; i++) {
                            const conversion = conversions[i];
                            if (payload.toString() === conversion._id?.toString()) {
                                conversion.delivered = true;
                            }
                        }
                        // context.commit("setSuccessMessages",{
                        //     "conversion": "Successfully updated"
                        // })
                        context.commit("setConversions", conversions);
                    }

                    return { success: true, message: "Postback successfully sent" };
                }
            } catch (error) {
                console.error(error);
                const message = error?.response?.data?.message || "Something went wrong, please try again later";
                // context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
                return { success: false, message: message };
            }
            return {
                success: false,
                message: "Something Went Wrong, please try again later or contact us.",
            };
        },
        getOrders: async (context, payload) => {
            try {
                const result = await Api.getOrders(payload, vueAuth.getToken());
                if (result.status == 200) {
                    context.commit("setOrders", result.data?.data?.orders);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        updateOrder: async (context, payload) => {
            try {
                const result = await Api.updateOrder(payload, vueAuth.getToken());
                if (result.status == 200) {
                    // context.commit("setOrders", result.data?.data?.offers);
                    const orders = context.state.orders;
                    for (let i = 0; i < orders.length; i++) {
                        const order = orders[i];
                        // console.log("---order");
                        if (payload.ids.includes(order._id?.toString())) {
                            order.status = payload.status == "confirmed" ? "verified" : payload.status;
                        }
                    }
                    // context.commit("setSuccessMessages",{
                    //     "order": "Successfully updated"
                    // })
                    context.commit("setOrders", orders);

                    return {
                        success: true,
                        message:
                            payload.status == "confirmed"
                                ? "Order Successfully Verified, The Payment Will Be Processed Soon"
                                : "Order Successfully Rejected",
                    };
                }
            } catch (error) {
                console.error(error);
                const message = error?.response?.data?.message || "Something went wrong, please try again later";
                // console.log("-----------error message", message);
                // context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
                return { success: false, message: message };
            }
            return false;
        },
        getUsers: async (context, payload) => {
            try {
                const result = await Api.getUsers(payload, vueAuth.getToken());
                // console.log("----result", result?.data);
                if (result.status == 200) {
                    context.commit("setUsers", result.data?.data?.users);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getVisitor: async (context, payload) => {
            try {
                const result = await Api.getVisitor(payload, vueAuth.getToken());
                // console.log('----result', result?.data);
                if (result.status == 200) {
                    context.commit("setVisitor", result.data?.data?.user);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        updateUsers: async (context, payload) => {
            try {
                const result = await Api.updateUsers(payload, vueAuth.getToken());
                if (result.status == 200) {
                    // context.commit("setConversions", result.data?.data?.offers);
                    const users = context.state.users;

                    for (let i = 0; i < users.length; i++) {
                        const user = users[i];
                        if (payload.ids?.includes(user._id)) {
                            if (payload.blocked !== undefined) {
                                user.blocked = payload.blocked || false;
                            }
                        }
                    }
                    context.commit("setSuccessMessages", {
                        contest: "Successfully updated",
                    });
                    context.commit("setUsers", users);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getDailyStats: async (context, payload) => {
            try {
                const result = await Api.getDailyStats(payload, vueAuth.getToken());
                // console.log("----result", result?.data);
                if (result.status == 200) {
                    context.commit("setStats", result.data?.data?.stats);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getReports: async (context, payload) => {
            try {
                const result = await Api.getReports(payload, vueAuth.getToken());
                // console.log("----result", result?.data);
                if (result.status == 200) {
                    context.commit("setReports", result.data?.data?.reports);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getGlobalStats: async (context, payload) => {
            try {
                const result = await Api.getGlobalStats(payload, vueAuth.getToken());
                // console.log("----result", result?.data);
                if (result.status == 200) {
                    context.commit("setGlobalStats", result.data?.data);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getAlerts: async (context, payload) => {
            return;
            try {
                const result = await Api.getAlerts(payload, vueAuth.getToken());
                // console.log("----result get alerts", result?.data);
                if (result.status == 200) {
                    context.commit("setAlerts", result.data);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getClicks: async (context, payload) => {
            try {
                const result = await Api.getClicks(payload, vueAuth.getToken());
                if (result.status == 200) {
                    context.commit("setClicks", result.data?.data?.clicks);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getViolations: async (context, payload) => {
            try {
                const result = await Api.getViolations(payload, vueAuth.getToken());
                if (result.status == 200) {
                    context.commit("setViolations", result.data?.data?.violations);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getReward: async (context, payload) => {
            try {
                const result = await Api.getReward(payload, vueAuth.getToken());
                // console.log("---result", result?.data?.data);
                if (result.status == 200) {
                    context.commit("setReward", result.data?.data?.reward);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getRewards: async (context, payload) => {
            try {
                const result = await Api.getRewards(payload, vueAuth.getToken());
                // console.log("---result", result?.data?.data);
                if (result.status == 200) {
                    context.commit("setRewards", result.data?.data?.rewards);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        createReward: async (context, payload) => {
            context.commit("setLoads", { createReward: true });
            try {
                const result = await Api.createReward(payload, vueAuth.getToken());
                // console.log("---result", result?.data);
                if (result.status == 200) {
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            context.commit("setLoads", { createReward: false });

            return false;
        },
        updateReward: async (context, payload) => {
            context.commit("setLoads", { updateReward: true });
            try {
                context.commit("setErrors", { reward: undefined });
                context.commit("setSuccessMessages", { reward: undefined });
                const result = await Api.updateReward(payload, vueAuth.getToken());
                // console.log('---result', result?.data);
                if (result.status == 200) {
                    const rewards = context.state.rewards;

                    for (let i = 0; i < rewards.length; i++) {
                        const reward = rewards[i];
                        if (payload.ids?.includes(reward._id)) {
                            if (payload.active !== undefined) {
                                reward.active = payload.active || false;
                            }
                        }
                    }
                    context.commit("setSuccessMessages", {
                        reward: "Successfully updated",
                    });
                    context.commit("setRewards", rewards);
                }
            } catch (error) {
                // console.error('updateReward ------------', error.response.data.message)
                context.commit("setErrors", {
                    reward: error?.response?.data?.message || "Something went wrong, please try again later",
                });
            }
            context.commit("setLoads", { updateReward: false });
        },
        deleteReward: async (context, payload) => {
            try {
                const result = await Api.deleteReward(payload, vueAuth.getToken());
                // console.log('---result', result?.data);
                if (result.status == 200) {
                    // context.commit("setOffers", result.data?.data?.offers);
                    const rewards = context.state.rewards;
                    const index = rewards.findIndex((x) => x._id == payload);
                    // console.log("---index", { index }, rewards.length);
                    if (index > -1) {
                        // console.log("---inside");
                        rewards.splice(index, 1);
                    }
                    // console.log("---index after", rewards.length);

                    context.commit("setRewards", rewards);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getSettings: async (context, payload) => {
            try {
                const result = await Api.getSettings(vueAuth.getToken());
                if (result.status == 200) {
                    context.commit("setSettings", result.data?.data);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getReferralReports: async (context, payload) => {
            try {
                const result = await Api.getReferralReports(payload, vueAuth.getToken());
                // console.log("----result", result?.data);
                if (result.status == 200) {
                    context.commit("setReferralReports", result.data?.data?.reports);
                    context.commit("setReferralLink", result.data?.data?.referralLink);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getReferralSummary: async (context, payload) => {
            try {
                const result = await Api.getReferralSummary(payload, vueAuth.getToken());
                // console.log("----result", result?.data);
                if (result.status == 200) {
                    context.commit("setReferralSummary", result.data?.data?.referrals);
                    context.commit("setReferralLink", result.data?.data?.referralLink);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        addParticipantEmail: async (context, payload) => {
            try {
                const result = await Api.addParticipantEmail(payload, vueAuth.getToken());
                // console.log("----result addParticipantEmail", result?.data);
                if (result.status == 200) {
                    context.commit("setParticipant", result.data?.data?.user);
                    const isDevLocal = process.env.VUE_APP_SITE_DOMAIN;
                    // console.log("---isDevLocal", isDevLocal);
                    if (isDevLocal == "localhost") {
                        document.cookie = `user_id=` + result.data?.data?.user?.user_id + ";path=/";
                    } else {
                        document.cookie =
                            `user_id=` +
                            result.data?.data?.user?.user_id +
                            ";path=/; sameSite=None; Secure; domain=.adviral.link";
                    }
                }
            } catch (error) {
                console.error(error);
                context.commit("setErrors", {
                    addParticipantEmail: error?.response?.data || "Something went wrong, please try again later",
                });
            }
            return false;
        },
        addParticipantProvider: async (context, payload) => {
            try {
                const result = await Api.addParticipantProvider(payload, vueAuth.getToken());
                // console.log("----result addParticipantProvider", result?.data);
                if (result.status == 200) {
                    context.commit("setParticipant", result.data?.data?.user);
                    const isDevLocal = process.env.VUE_APP_SITE_DOMAIN;
                    // console.log("---isDevLocal", isDevLocal);
                    if (isDevLocal == "localhost") {
                        document.cookie = `user_id=` + result.data?.data?.user?.user_id + ";path=/";
                    } else {
                        document.cookie =
                            `user_id=` +
                            result.data?.data?.user?.user_id +
                            ";path=/; sameSite=None; Secure; domain=.adviral.link";
                    }
                }
            } catch (error) {
                console.error(error);
                context.commit("setErrors", {
                    addParticipantProvider: error?.response?.data || "Something went wrong, please try again later",
                });
            }
            return false;
        },
        getContestParticipants: async (context, payload) => {
            try {
                const result = await Api.getContestParticipants(payload, vueAuth.getToken());
                // console.log("----result getContestParticipants", result?.data);
                if (result.status == 200) {
                    context.commit("setContestParticipants", result.data?.data?.participants);
                }
            } catch (error) {
                console.error(error);
                context.commit("setErrors", {
                    // addParticipantProvider: error?.response?.data || "Something went wrong, please try again later",
                });
            }
            return false;
        },
        setContestWinner: async (context, payload) => {
            try {
                const result = await Api.setContestWinner(payload, vueAuth.getToken());
                if (result.status == 200) {
                }
            } catch (error) {
                console.error(error);
                context.commit("setErrors", {
                    // addParticipantProvider: error?.response?.data || "Something went wrong, please try again later",
                });
            }
            return false;
        },
        getHelp: async (context, payload) => {
            try {
                const result = await Api.getHelp(payload, vueAuth.getToken());
                // console.log("---result", result?.data?.data);
                if (result.status == 200) {
                    context.commit("setHelp", result.data?.data);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        verifyAction: async (context, payload) => {
            try {
                const result = await Api.verifyAction(payload, vueAuth.getToken());
                // console.log("---result", result?.data?.data);
                if (result.status == 200) {
                    return true;
                }
            } catch (error) {
                console.error(error);
            }
            return false;
        },
        isReferrerValid: async (context, payload) => {
            try {
                const result = await Api.isReferrerValid(payload, vueAuth.getToken());

                if (result.status == 200) {
                    return true;
                }
            } catch (error) {
                console.error(error);
                context.commit("setAuthError", error?.response?.data || "Please enter valid information");
            }
            return false;
        },
        getThemes: async (context, payload) => {
            try {
                // console.log("--get contests action");
                const result = await Api.getThemes(payload, vueAuth.getToken());
                // console.log("---result", result?.data?.data);
                if (result.status == 200) {
                    context.commit("setThemes", result.data?.data?.themes);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getViolators: async (context, payload) => {
            try {
                // console.log("--get contests action");
                const result = await Api.getViolators(payload, vueAuth.getToken());
                // console.log("---result", result?.data?.data);
                if (result.status == 200) {
                    context.commit("setViolators", result.data?.data?.violators);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getPayments: async (context, payload) => {
            try {
                // console.log("--get contests action");
                const result = await Api.getPayments(payload, vueAuth.getToken());
                // console.log("---result", result?.data?.data);
                if (result.status == 200) {
                    context.commit("setPayments", result.data?.data?.payments);
                    context.commit("setMonthEarning", result.data?.data?.month_earning);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        getPaymentHistory: async (context, payload) => {
            try {
                const result = await Api.getPaymentHistory(payload, vueAuth.getToken());
                if (result.status == 200) {
                    context.commit("setPaymentHistory", result.data?.data?.payments);
                }
            } catch (error) {
                console.error(error);
                context.commit(
                    "setError",
                    error?.response?.data?.message || "Something went wrong, please try again later",
                );
            }
            return false;
        },
        savePaymentMethod: async (context, payload) => {
            try {
                // console.log("--get contests action");
                const result = await Api.savePaymentMethod(payload, vueAuth.getToken());
                // console.log("---result", result?.data);
                if (result.status == 200) {
                    // context.commit("setPayments", result.data?.data?.payments);
                    return true;
                }
            } catch (error) {
                console.error(error);
                context.commit("setErrors", {
                    savePayment: error?.response?.data?.message || "Something went wrong, please try again later",
                });
            }
            return false;
        },
    },
});
