<template>
    <div v-if="item.key.includes('youtube-')">
        <div>
            <a
                :href="`${item.inputs[0]?.value}`"
                target="_blank"
                class="youtube-button"
            >
                <i class="fab fa-youtube youtube-icon" style=""></i>
                {{ content[item.key]?.["button-title"] }}
            </a>
        </div>

        <div class="mt-4" style="cursor: pointer" @click="onConfirm(item)">
            <span style="text-decoration: underline">{{ content[item.key]?.text }}</span>
        </div>
    </div>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Modal from "@/components/Modal";

const content = {
    'youtube-like': {
        'button-title': 'Like',
        'text': 'Already liked?'
    },
    'youtube-subscribe': {
        'button-title': 'Subscribe',
        'text': 'Already Subscribed?'
    },
    'youtube-comment': {
        'button-title': 'Comment',
        'text': 'Already commented?'
    },
    'youtube-watch': {
        'button-title': 'Watch',
        'text': 'Already watched?'
    },
}

export default {
    name: "youtube-social-card",
    components: {
        BaseButton,
        ClipLoader,
        Modal,
        // VPopover,
    },
    data() {
        return {
            selectAll: false,
            selected: {},
            model: 'Hello world is title',
            content: content
        };
    },
    props: {
        item: Object,
        onConfirm: Function
    },
    updated() {},
    mounted() {},

};
</script>
<style></style>
