<template>
    <div v-if="!contest?.winners?.length > 0 && !isEnded" style="color: #333 !important;">
        <div class="row justify-content-center">
            <div class="col-md-2 col-2 px-0">
                <svg xmlns="http://www.w3.org/2000/svg" height="2" width="100%" viewBox="0 0 91 2" fill="none">
                    <path d="M0.986694 0.98819H89.9493" stroke="#333333" stroke-width="0.577679" stroke-linecap="round" />
                </svg>
            </div>
            <div class="col-md-3 col-4 text-center" style="padding-left: unset; padding-right: unset; color: #333;
                    text-align: center;
                    font-family: Open Sans;
                    font-size: 11.554px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;">
                {{ participant?.email ? 'Logged In As' : 'Login With' }}
            </div>
            <div class="col-md-2 col-2 px-0">


                <svg xmlns="http://www.w3.org/2000/svg" height="2" width="100%" viewBox="0 0 91 2" fill="none">
                    <path d="M0.986694 0.98819H89.9493" stroke="#333333" stroke-width="0.577679" stroke-linecap="round" />
                </svg>
            </div>



        </div>
        <div class="column align-items-center justify-content-center" v-if="participant?.email">
            <span class="mr-2" style="font-size: 18px; font-weight: 600;">{{ participant?.email }}</span>

            <div>
                <base-button :style="{ background: buttonColor }" size="sm" @click="Logout">Logout</base-button>
            </div>


        </div>
        <div class="row mt-1 justify-content-center" v-else>
            <div 
                class="icon-background mx-4"
                :style="{ background: contest?.main_color }"
                @click="() => controlShowLoginModal(true)"
            >
                <img src="../../assets/images/email.png" />
            </div>
            <!-- <v-facebook-login-scope app-id="984297669579294" @login="handleFacebookLogin"
                :login-options="{ scope: 'email,user_likes' }">
                <div class="icon-background mx-4" :style="{ background: contest?.main_color }" ref="facebookSvg"
                    slot-scope="scope" @click="scope.login" style="cursor: pointer"><img
                        src="../../assets/images/facebook.png" style="height: 20px; width: 20px;" /></div>

            </v-facebook-login-scope> -->
            <!-- <div id="googleSignInButton" data-onsuccess="onSignIn"></div> -->
            <div 
                class="icon-background mx-4"
                :style="{ background: contest?.main_color }"
                @click="signin"
            >
                <!-- siging 1
                <i class=""></i> -->
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                    <path fill="none" d="M0 0h48v48H0z"></path>
                </svg>
            </div>



        </div>
    </div>
</template>

<script>
// import { VFBLoginScope as VFacebookLoginScope } from "vue-facebook-login-component";
import Vue from 'vue'
const clientId = "504688115086-gjogver9ihvfn530idadep4o44tuofec.apps.googleusercontent.com"

export default {
    data() {
        return {

        };
    },
    components: {
        // VFacebookLoginScope,

    },
    props: {
        controlShowLoginModal: Function,
        handleFacebookLogin: Function,
        handleGoogleLogin: Function,
        scope: Object,
        contest: Object,
        participant: Object,
        Logout: Function,
        isEnded: Boolean,
        buttonColor: String

    },
    async mounted() {
        console.log('-mounted', this.participant);
        // gapi.load('auth2', () => {
        //     gapi.auth2.init({
        //         client_id: clientId,
        //         scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
        //         state: { user_id: this.participant?._id, contest: this.contest?._id },
        //         redirect_uri: "https://api.adviral.io/contest/auth/login/google/callback",
        //     }).then(function () {
        //         console.log('Google Auth initialized.');
        //     });
        // });


    },
    methods: {
        async signin() {
            // this.client.requestAccessToken()
            // google.accounts.id.prompt(); // Display the One Tap prompt
            const state = encodeURIComponent(JSON.stringify({ user_id: this.participant?.user_id, contest: this.contest._id, contest_link: location.href }));
            const scope = `https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile`
            const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&response_type=code&redirect_uri=https://api.adviral.io/contest/auth/login/google/callback&scope=${scope}&state=${state}`;

            window.location.href = authUrl;
        }
    }
};
</script>

<style>
.icon-background {
    /* border */
    border-radius: 5.777px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    min-height: 30px;
    cursor: pointer;
}

.icon-background img,
.icon-background svg {
    height: 20px;
    width: 20px;
}

.icon-background i {
    font-size: 15px;
    color: white;
}
</style>
  