<template>
    <div>
        <table class="table tablesorter" :class="tableClass">
            <thead :class="theadClasses">
                <tr>
                    <slot name="columns">
                        <th v-for="column in columns" :key="column" @click="setSortBy(column)" style="cursor: pointer">
                            <div class="row align-items-center ml-0">
                                <span>{{ column }}</span>
                                <i
                                    v-if="sortBy == getSortByName(column)"
                                    class="tim-icons ml-2"
                                    :class="{
                                        'icon-minimal-down': sort == -1,
                                        'icon-minimal-up': sort == 1,
                                    }"
                                ></i>
                            </div>
                        </th>
                    </slot>
                </tr>
            </thead>
            <tbody :class="tbodyClasses">
                <tr v-if="!loading" v-for="(item, index) in showedData" :key="index">
                    <slot :row="item">
                        <td v-for="(column, index) in columns" :key="index" v-if="column == 'select'">
                            <input v-model="selected[item._id]" type="checkbox" />
                        </td>
                        <td
                            v-for="(column, index) in columns"
                            :key="index"
                            v-if="hasValue(item, column) && !['control', 'select', 'logo'].includes(column)"
                            style="max-width: 400px"
                        >
                            <span>{{ itemValue(item, column) }}</span>
                        </td>

                        <td v-for="(column, index) in columns" :key="index" v-if="column == 'control'">
                            <base-button
                                class="mr-2"
                                style="width: 83px"
                                @click="viewOffer(item._id)"
                                size="sm"
                                type="info"
                                fill
                            >
                                <span style="margin-left: 0px !important"> View</span>
                            </base-button>
                            <base-button
                                style="width: 83px"
                                class="mr-2"
                                @click="switchStatus([item._id], item.active)"
                                size="sm"
                                :type="item.active ? 'primary' : 'secondary'"
                                fill
                            >
                                <clip-loader
                                    v-if="loadingItem?.['active'] == item._id"
                                    color="#fff"
                                    size="15px"
                                    :loading="true"
                                ></clip-loader>

                                <span style="margin-left: 0px !important" v-else>{{
                                    item.active ? "Disable" : "Enable"
                                }}</span>
                            </base-button>

                            <base-button
                                style="width: 83px"
                                class="mt-2"
                                @click="pinOffer([item._id], item.pinned)"
                                size="sm"
                                :type="item.pinned ? 'primary' : 'secondary'"
                                fill
                            >
                                <clip-loader
                                    v-if="loadingItem?.['pin'] == item._id"
                                    color="#fff"
                                    size="15px"
                                    :loading="true"
                                ></clip-loader>

                                <span style="margin-left: 0px !important" v-else>{{
                                    item.pinned ? "Unpin" : "Pin"
                                }}</span>
                            </base-button>
                        </td>
                    </slot>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import moment from "moment";
import { calculateDataToShowRanges } from "../services/utils";
import BaseButton from "./BaseButton.vue";
import { Pagination } from "@/components";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    name: "offer-table",
    components: {
        BaseButton,
        Pagination,
        ClipLoader,
    },
    data() {
        return {
            selectAll: false,
            selected: {},
            sortBy: "_id",
            sort: 1,
        };
    },
    props: {
        columns: {
            type: Array,
            default: () => [],
            description: "Table columns",
        },
        data: {
            type: Array,
            default: () => [],
            description: "Table data",
        },
        type: {
            type: String, // striped | hover
            default: "",
            description: "Whether table is striped or hover type",
        },
        theadClasses: {
            type: String,
            default: "",
            description: "<thead> css classes",
        },
        tbodyClasses: {
            type: String,
            default: "",
            description: "<tbody> css classes",
        },
        switchStatus: {
            type: Function,
        },
        pinOffer: {
            type: Function,
        },
        viewOffer: {
            type: Function,
        },
        loadingItem: {
            type: Object,
        },
        perPage: { type: Number },
        currentPage: { type: Number },
        onSelect: {
            type: Function,
        },
        selectedItems: {
            type: Array,
        },
        loading: Boolean,
        label: String,
    },
    updated() {},
    computed: {
        tableClass() {
            return this.type && `table-${this.type}`;
        },
        showedData() {
            // console.log(
            //     "---------showedData",
            //     this.currentPage == 1 ? 0 : this.currentPage * this.perPage,
            //     this.currentPage * this.perPage + this.perPage,
            // );
            const { sort, sortBy } = this;
            // console.log("---sort", sort, sortBy);
            let data = this.data;
            if (sortBy) {
                data = data.sort((a, b) => {
                    // console.log("---inside", sortBy, sort);
                    // console.log(isNaN(a?.[sortBy]), a[sortBy].localeCompare);
                    if (sort == 1) {
                        return isNaN(a?.[sortBy]) && a[sortBy]?.localeCompare && b[sortBy]?.localeCompare
                            ? a[sortBy]?.localeCompare(b[sortBy])
                            : a?.[sortBy] - b?.[sortBy];
                    } else {
                        return isNaN(a?.[sortBy]) && a[sortBy]?.localeCompare && b[sortBy]?.localeCompare
                            ? b[sortBy]?.localeCompare(a[sortBy])
                            : b?.[sortBy] - a?.[sortBy];
                    }
                });
            }

            data = calculateDataToShowRanges(this.currentPage, this.perPage, data);

            // for (let i = 0; i < data.length; i++) {
            //   const offer = data[i];
            //   console.log('---offer', this.pinnedOffers, offer._id);
            //   if (this.pinnedOffers?.includes(offer._id)) {
            //     offer.pinned = true
            //   }
            // }

            return data;
        },
        totalPages() {
            return this.data.length / this.perPage;
        },
    },
    methods: {
        hasValue(item, column) {
            return item[column.toLowerCase()] !== "undefined";
        },
        itemValue(item, column) {
            if (column === "Register Date") {
                return moment(item.register_date).format("YYYY-MM-DD");
            }
            return item[column.toLowerCase()];
        },
        setSortBy(column) {
            // console.log("---clicked", column);
            switch (column) {
                case "Status":
                    this.sortBy = "active";
                    break;
                default:
                    this.sortBy = column?.toLowerCase();
                    break;
            }

            this.sort = !this.sort || this.sort == -1 ? 1 : -1;
        },
        getSortByName(column) {
            switch (column) {
                case "Status":
                    return "active";
                default:
                    return column.toLowerCase();
            }
        },
    },
    watch: {
        currentPage() {},
        selectAll(value) {
            this.onSelect(
                this.showedData.map((x) => x.id),
                value,
            );
        },
        selectedItems(value) {
            const obj = {};
            for (let i = 0; i < value.length; i++) {
                obj[value[i]] = true;
            }
            if (JSON.stringify(obj) !== JSON.stringify(this.selected)) {
                this.selected = { ...obj };
            }
        },
        selected(value) {
            const ids = Object.keys(value);
            this.onSelect(ids, value);
        },
    },
};
</script>
<style></style>
