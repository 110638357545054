<template>
    <div class="wrapper">
        <side-bar :backgroundColor="backgroundColor">
            <template slot="links">
                <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="tim-icons icon-chart-pie-36" />
                <!-- <sidebar-link to="/offerwalls" :name="$t('sidebar.offerwalls')" icon="tim-icons icon-pin"/> -->
                <sidebar-link to="/offers" :name="$t('sidebar.offers')" icon="tim-icons icon-controller" />
                <sidebar-link to="/stats" :name="$t('sidebar.stats')" icon="tim-icons icon-chart-bar-32" />
                <sidebar-link
                    to="/referrals"
                    :name="$t('sidebar.referrals')"
                    icon="tim-icons icon-single-02"
                />
                <sidebar-link to="/rewards" :name="$t('sidebar.rewards')" icon="tim-icons icon-gift-2" />
                <sidebar-link to="/contests" :name="$t('sidebar.contests')" icon="tim-icons icon-gift-2" />
                <sidebar-link to="/themes" :name="$t('sidebar.themes')" icon="far fa-clone" />
                <sidebar-link to="/payment" :name="$t('sidebar.payment')" icon="tim-icons icon-credit-card" />
                <sidebar-link to="/help" :name="$t('sidebar.help')" icon="tim-icons icon-alert-circle-exc" />
                <!-- <sidebar-link v-if="user?.is_admin" to="/violators" :name="$t('sidebar.violators')" icon="tim-icons icon-alert-circle-exc" /> -->
                <!-- <sidebar-link to="/orders" :name="$t('sidebar.orders')" icon="tim-icons icon-pin"/>
        <sidebar-link to="/conversions" :name="$t('sidebar.conversions')" icon="tim-icons icon-atom"/>
        <sidebar-link to="/users" :name="$t('sidebar.users')" icon="tim-icons icon-atom"/>
        <sidebar-link to="/profile" :name="$t('sidebar.userProfile')" icon="tim-icons icon-single-02"/> -->
                <!-- <sidebar-link to="/notifications" :name="$t('sidebar.notifications')" icon="tim-icons icon-bell-55"/>
        <sidebar-link to="/table-list" :name="$t('sidebar.tableList')" icon="tim-icons icon-puzzle-10"/>
        <sidebar-link to="/typography" :name="$t('sidebar.typography')" icon="tim-icons icon-align-center"/>
        <sidebar-link to="/dashboard?enableRTL=true" :name="$t('sidebar.rtlSupport')" icon="tim-icons icon-world"/> -->
            </template>
        </side-bar>
        <div class="main-panel">
            <top-navbar></top-navbar>

            <!-- <sidebar-share :background-color.sync="backgroundColor"></sidebar-share> -->

            <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

            <content-footer></content-footer>
        </div>
    </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
// import SidebarShare from "./SidebarSharePlugin.vue";
import { mapGetters } from 'vuex';

export default {
    components: {
        TopNavbar,
        ContentFooter,
        DashboardContent,
        MobileMenu,
        // SidebarShare,
    },
    data() {
        return {
            backgroundColor: "primary",
        };
    },
    computed: {
      ...mapGetters([
        'user',
      ])
    },
    methods: {
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
    },
};
</script>
