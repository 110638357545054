<template>
    <div>
        <table class="table tablesorter" :class="tableClass">
            <thead :class="theadClasses">
                <tr>
                    <slot name="columns">
                        <th v-for="column in columns" :key="column" @click="setSortBy(column)" style="cursor: pointer">
                            <input
                                v-model="selectAll"
                                style="width: 15px"
                                v-if="column == 'select'"
                                class="form-control"
                                type="checkbox"
                            />
                            <div class="row align-items-center ml-0" v-else>
                                <span>{{ columnValue(column) }}</span>
                                <i
                                    v-if="sortBy == getSortByName(column)"
                                    class="tim-icons ml-2"
                                    :class="{
                                        'icon-minimal-down': sort == -1,
                                        'icon-minimal-up': sort == 1,
                                    }"
                                ></i>
                            </div>
                        </th>
                    </slot>
                </tr>
            </thead>
            <tbody :class="tbodyClasses">
                <tr v-if="!loading" v-for="(item, index) in showedData" :key="index">
                    <slot :row="item">
                        <td v-for="(column, index) in columns" :key="index" v-if="column == 'select'">
                            <input v-model="selected[item._id]" type="checkbox" />
                        </td>
                        <td
                            v-for="(column, index) in columns"
                            :key="index"
                            v-if="hasValue(item, column) && !['control', 'select', 'logo'].includes(column)"
                            style="max-width: 400px"
                            :style="{'min-width': column == 'Label' ? '80px' : 'unset' }"

                        >
                            <div v-if="column == 'Label' && label === 'offer'" style="word-break: break-word">
                                <img :src="item.offer?.logo" style="max-width: 40px" class="test" />
                                <span>{{ `${item.offer.offer_id} - ${item.offer.name}` }}</span>
                            </div>
                            <span v-else-if="column == 'Label' && label !== 'offer'" style="word-break: break-word">
                                {{ item[label] }}
                            </span>
                            <span v-else-if="column == 'Conversions'" style="word-break: break-word">
                                {{ item.totalConversions }}
                            </span>
                            <span v-else-if="column == 'Clicks'" style="word-break: break-word">
                                {{ item.totalClicks }}
                            </span>
                            <span v-else-if="column == 'Payout'" style="word-break: break-word">
                                {{ item.earnings }}
                            </span>

                            <span v-else>{{ itemValue(item, column) }}</span>
                        </td>
                    </slot>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import BaseButton from "./BaseButton.vue";
import { Pagination } from "@/components";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    name: "offer-table",
    components: {
        BaseButton,
        Pagination,
        ClipLoader,
    },
    data() {
        return {
            selectAll: false,
            selected: {},
            sortBy: "_id",
            sort: 1,
        };
    },
    props: {
        columns: {
            type: Array,
            default: () => [],
            description: "Table columns",
        },
        data: {
            type: Array,
            default: () => [],
            description: "Table data",
        },
        type: {
            type: String, // striped | hover
            default: "",
            description: "Whether table is striped or hover type",
        },
        theadClasses: {
            type: String,
            default: "",
            description: "<thead> css classes",
        },
        tbodyClasses: {
            type: String,
            default: "",
            description: "<tbody> css classes",
        },
        loading: Boolean,
        label: String,
        groupBy: String
    },
    updated() {},
    computed: {
        tableClass() {
            return this.type && `table-${this.type}`;
        },
        showedData() {
            // return this.data;
            const { sort, sortBy } = this;
            // console.log("---sort", sort, sortBy);
            let data = this.data;
            if (sortBy) {
                data = data.sort((a, b) => {
                    // console.log("---inside", sortBy, sort);
                    // console.log(isNaN(a?.[sortBy]), a[sortBy].localeCompare);
                    const aValue = this.itemValue(a, sortBy)
                    const bValue = this.itemValue(b, sortBy)
                    // console.log('---aValue', aValue, bValue);
                    if (sort == 1) {
                        return isNaN(aValue) && aValue?.localeCompare && bValue?.localeCompare
                            ? aValue?.localeCompare(bValue)
                            : aValue - bValue;
                    } else {
                        return isNaN(aValue) && aValue?.localeCompare && bValue?.localeCompare
                            ? bValue?.localeCompare(aValue)
                            : bValue - aValue;
                    }
                });
            }

            return data;
        },
    },
    mounted() {
        // console.log("---mounted stats table", this.columns);
    },
    methods: {
        columnValue(column) {
            // console.log("---column", column);
            if (column === "Label") {
                return this.label;
            }

            return column;
        },
        hasValue(item, column) {
            return item[column.toLowerCase()] !== "undefined";
        },
        itemValue(item, column) {
            // console.log('----item, column', item, column);
            if (column.toLowerCase() === "conversions") {
                return item.totalConversions
            }
            if (column.toLowerCase() === "clicks") {
                return item.totalClicks
            }
            if (column.toLowerCase() === "label") {
                // console.log('---this.groupBy', this.groupBy);
                if (this.groupBy === 'offer') {
                    return item[this.groupBy]?.offer_id
                } else {
                    return item[this.groupBy]
                }
            }
            if (column.toLowerCase() === "payout") {
                return item.earnings
            }
            return item[column.toLowerCase()];
        },
        setSortBy(column) {
            // console.log("---clicked", column);
            switch (column) {
                case "Status":
                    this.sortBy = "active";
                    break;
                default:
                    this.sortBy = column?.toLowerCase();
                    break;
            }

            this.sort = !this.sort || this.sort == -1 ? 1 : -1;
        },
        getSortByName(column) {
            switch (column) {
                case "Status":
                    return "active";
                default:
                    return column.toLowerCase();
            }
        },
    },
    watch: {
        currentPage() {},
        selectAll(value) {
            this.onSelect(
                this.showedData.map((x) => x.id),
                value,
            );
        },
        selectedItems(value) {
            const obj = {};
            for (let i = 0; i < value.length; i++) {
                obj[value[i]] = true;
            }
            if (JSON.stringify(obj) !== JSON.stringify(this.selected)) {
                this.selected = { ...obj };
            }
        },
        selected(value) {
            const ids = Object.keys(value);
            this.onSelect(ids, value);
        },
    },
};
</script>
<style></style>
