<template>
    <div class="row justify-content-center text-center my-2 mb-2">
        <div v-if="contest?.winners?.length > 0 || isEnded" class="contest-countdown col-md-12 col-12 row justify-content-center">
            <span>Ended</span>
        </div>
        <div v-else class="contest-countdown col-md-12 col-12 row justify-content-center" style="">
            <div class="row align-items-center col-md-12 col-12 justify-content-between">
            <!-- <div v-for="(item, index) in showCountdown" class="countdown-item" :class="{ 'hide-mobile': index === 6 || index === 5}"> -->
            <div v-for="(item, index) in showCountdown" class="countdown-item" >
                
                <div v-if="item.name !== ':'"
                    style="color: #333;
                    text-align: center;
                    font-family: Open Sans;
                    font-size: 9.243px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;"
                    >{{ item.name }}
                </div>
                <div v-else class="mt-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="16" viewBox="0 0 5 20" fill="none">
                        <circle cx="2.32805" cy="3.05016" r="2.31072" fill="#333333"/>
                        <circle cx="2.32805" cy="16.9145" r="2.31072" fill="#333333"/>
                    </svg>

                </div>
                <div class="row justify-content-center mt-2" v-if="item?.name !== ':'">
                    <div v-for="value in item.value" 
                        style="; border-radius: 5px;"
                        :style="{background: contest?.main_color || 'rgb(51, 51, 51)'}"
                        class="px-2 py-2 mx-1"
                    >
                        <span 
                            style="
                                color: #FFF;
                                text-align: center;
                                font-family: Open Sans;
                                font-size: 15px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            "
                        >
                            {{ value }}
                        </span>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <!-- <div style="cursor: pointer" class="col-md-2 col-2 contest-balance" @click="controlShowHistory(true)">
            <span>{{ participant?.entries }}</span>
        </div> -->
    </div>
</template>

<script>

import './contestCard.css'

export default {
    props: {
        contest: Object | null,
        isEnded: Boolean,
        showCountdown: Array
         
    },
    data() {
        return {
            showTooltip: false,
        };
    },
    methods: {

    }
};
</script>

<style>
.countdown-item {
    padding-left: 2px;
    padding-right: 2px;
}
.contest-countdown {
    padding-right: 40px !important;
    padding-left: 40px !important;

}

@media screen and (max-width: 769px) {

    .countdown-item {
        padding-left: 0px;
        padding-right: 0px;
    }

    .contest-countdown {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }

}

</style>