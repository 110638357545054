<template>
    <div v-if="item.key.includes('facebook-')">
        <div>
            <a
                :href="`${item.inputs[0]?.value}`"
                target="_blank"
                class="facebook-button"
            >
                <i class="fab fa-facebook facebook-icon" style=""></i>
                {{ content[item.key]?.["button-title"] }}
            </a>
        </div>

        <div class="mt-4" style="cursor: pointer" @click="onConfirm(item)">
            <span style="text-decoration: underline">{{ content[item.key]?.text }}</span>
        </div>
    </div>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Modal from "@/components/Modal";

const content = {
    'facebook-like-page': {
        'button-title': 'Like',
        'text': 'Already Liked?'
    },
    'facebook-visit': {
        'button-title': 'Visit',
        'text': 'Already Visited?'
    },
    'facebook-view': {
        'button-title': 'View',
        'text': 'Already Viewed?'
    },
}

export default {
    name: "facebook-social-card",
    components: {
        BaseButton,
        ClipLoader,
        Modal,
        // VPopover,
    },
    data() {
        return {
            selectAll: false,
            selected: {},
            content: content
        };
    },
    props: {
        item: Object,
        onConfirm: Function
    },
    updated() {},
    mounted() {},

};
</script>
<style scoped>
.facebook-button {
    background-color: #1977F2;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

</style>
