<template>
    <div>
        
        <iframe v-if="isYoutubeVideo" width="100%" height="225" :src="`https://www.youtube.com/embed/${getYoutubeId()}?autoplay=1`" frameborder="0" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>


        <blockquote v-else-if="isTikTokVideo" class="tiktok-embed" :cite="link" :data-video-id="getTiktokId()" style="max-width: 605px;min-width: 325px;" > 
            <section> 
                <a target="_blank">Loading...</a> 
            </section> 
        </blockquote> 
        <img v-else-if="link" :src="link" style="max-height: 225px; width: 100%; border-radius: 0px;" />

    </div>
</template>
<script>
import { isValidTikTokUrl, isValidYouTubeUrl, getYouTubeVideoId, getTikTokVideoId  } from '../../services/utils';


export default {
    name: "contest-media",
    components: {

    },
    data() {
        return {
            selectAll: false,
            selected: {},
            model: 'Hello world is title'
        };
    },
    props: {
        link: String
    },
    updated() {
        
    },
    created() {
        const script = document.createElement('script');
    script.src = 'https://www.tiktok.com/embed.js';
    script.async = true;
    document.body.appendChild(script);
    },
    computed: {
        isYoutubeVideo() {
            return this.link && isValidYouTubeUrl(this.link)
        },
        isTikTokVideo() {
            return this.link && isValidTikTokUrl(this.link)
        }
    },
    methods: {
        getTiktokId() {
            return getTikTokVideoId(this.link)
        },
        getYoutubeId() {
            return getYouTubeVideoId(this.link)
        }
    }
    
};
</script>
<style>
  .tiktok-container {
    width: 100%;
    /* height: 100px; Desired width */
  }

  .tiktok-container iframe {
    width: 100%;
    height: auto;
  }
</style>
