<template>
    <div
        class="form-group"
        :class="{
            'input-group': hasIcon,
            'input-group-focus': focused,
        }"
    >
        <slot name="label">
            <label v-if="label" class="control-label" :class="{ bold: source == 'offer' }">
                {{ label }}
            </label>
        </slot>
        <slot name="addonLeft">
            <span v-if="addonLeftIcon" class="input-group-prepend">
                <div class="input-group-text">
                    <i :class="addonLeftIcon"></i>
                </div>
            </span>
        </slot>
        <slot>
            <textarea
                v-model="modelValue"
                v-bind="$attrs"
                v-on="listeners"
                class="form-control"
                aria-describedby="addon-right addon-left"
                :rows="rows"
                style="border: 1px solid #2b3553 !important; border-color: #2b3553; border-radius: 0.4285rem"
            ></textarea>
        </slot>
        <slot name="addonRight">
            <span v-if="addonRightIcon" class="">
                <div class="input-group-text">
                    <i :class="addonRightIcon"></i>
                </div>
            </span>
        </slot>
        <slot name="helperText"
            ><small>{{ helperText }}</small></slot
        >
    </div>
</template>
<script>
export default {
    inheritAttrs: false,
    name: "base-input",
    props: {
        label: {
            type: String,
            description: "Input label",
        },
        value: {
            type: [String, Number],
            description: "Input value",
        },
        rows: String,
        addonRightIcon: {
            type: String,
            description: "Input icon on the right",
        },
        addonLeftIcon: {
            type: String,
            description: "Input icon on the left",
        },
        onChange: {
            type: Function,
            description: "Input icon on the left",
        },
        helperText: {
            type: String,
        },
        source: String,
    },
    model: {
        prop: "value",
        event: "input",
    },
    data() {
        return {
            focused: false,
            modelValue: null,
        };
    },
    created() {
        this.modelValue = this.value;
    },
    computed: {
        hasIcon() {
            const { addonRight, addonLeft } = this.$slots;
            return (
                addonRight !== undefined ||
                addonLeft !== undefined ||
                this.addonRightIcon !== undefined ||
                this.addonLeftIcon !== undefined
            );
        },
        listeners() {
            return {
                ...this.$listeners,
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus,
            };
        },
    },
    methods: {
        onInput(evt) {
            this.$emit("input", evt.target.value);
        },
        onFocus() {
            this.focused = true;
        },
        onBlur() {
            this.focused = false;
        },
    },
    watch: {
        modelValue(val) {
            // console.log('----modelValue', val, this.onChange);
            if (this.onChange) {
                this.onChange(val);
            }
        },
        value(val) {
            // console.log('----value', val);
            this.modelValue = val;
        },
    },
};
</script>
<style></style>
