<template>
  <div class="sidebar"
       :data="backgroundColor">
    <!--
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
    <!-- -->
    <div class="sidebar-wrapper" id="style-3">
      <div class="custom-logo row mx-0" style="    justify-content: start;
    align-items: center;
    padding-left: 20px !important;">
        <a href="/"
           aria-label="sidebar mini logo"
           class="simple-text logo-mini mr-2">
          <div class="logo-img"
               :class="{'logo-img-rtl': $rtl.isRTL}">
               <img 
                        src="/img/logo-adviral.png"
                        style="width: 60px"
                    />
          </div>
        </a>
        <a href="/" class="simple-text logo-normal" style="    color: white;
    font-size: 20px;
    font-weight: 500;
    text-align: left;">
          {{title}}
        </a>
      </div>
      <slot>
        <div class="line" style="    width: 100%;
    height: 0.1px;
    background: white;"></div>

      </slot>
      <ul class="nav mt-0">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot name="links">
          <sidebar-link v-for="(link,index) in sidebarLinks"
                        :key="index"
                        :to="link.path"
                        :name="link.name"
                        :icon="link.icon">
          </sidebar-link>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
  import SidebarLink from "./SidebarLink";

  export default {
    props: {
      title: {
        type: String,
        default: "ADVIRAL"
      },
      backgroundColor: {
        type: String,
        default: "green"
      },
      activeColor: {
        type: String,
        default: "success",
        validator: value => {
          let acceptedValues = [
            "primary",
            "info",
            "success",
            "warning",
            "danger"
          ];
          return acceptedValues.indexOf(value) !== -1;
        }
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide() {
      return {
        autoClose: this.autoClose,
        addLink: this.addLink,
        removeLink: this.removeLink
      };
    },
    components: {
      SidebarLink
    },
    computed: {
      /**
       * Styles to animate the arrow near the current active sidebar link
       * @returns {{transform: string}}
       */
      arrowMovePx() {
        return this.linkHeight * this.activeLinkIndex;
      },
      shortTitle() {
        return this.title.split(' ')
          .map(word => word.charAt(0))
          .join('').toUpperCase();
      }
    },
    data() {
      return {
        linkHeight: 65,
        activeLinkIndex: 0,
        windowWidth: 0,
        isWindows: false,
        hasAutoHeight: false,
        links: []
      };
    },
    methods: {
      findActiveLink() {
        this.links.forEach((link, index) => {
          if (link.isActive()) {
            this.activeLinkIndex = index;
          }
        });
      },
      addLink(link) {
        const index = this.$slots.links.indexOf(link.$vnode);
        this.links.splice(index, 0, link);
      },
      removeLink(link) {
        const index = this.links.indexOf(link);
        if (index > -1) {
          this.links.splice(index, 1);
        }
      }
    },
    mounted() {
      console.log("sidebar active colors", this.backgroundColor, this.activeColor)
      this.$watch("$route", this.findActiveLink, {
        immediate: true
      });
    }
  };
</script>

<style>

.off-canvas-sidebar .logo:after, .sidebar .custom-logo:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 15px;
    height: 1px;
    width: calc(100% - 30px);
    background: hsla(0,0%,100%,.5);
}
</style>